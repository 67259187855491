import { useEffect, useState } from "react";
import EyeSVG from "../../../../common/svg/EyeSVG";
import FullHeartSVG from "../../../../common/svg/FullHeartSVG";
import HeartSVG from "../../../../common/svg/HeartSVG";
import LeftArrow from "../../../../common/svg/LeftArrow";
import instance from "../../../Feat/axios/instance";
import WarningToastify from "../../common/WarningToastify";
import MappingCurrencyType from "../../../Feat/common/MappingCurrencyType";

const TypeClinicNFT = (detailData: any) => {
  const { data } = detailData;
  const [currency, setCurrency] = useState("");
  let year;
  let month;
  let day;

  const clinicDate = data?.metaData?.clinicDate;

  if (clinicDate) {
    const dateObj = new Date(clinicDate);

    year = dateObj.getFullYear();
    month = dateObj.getMonth() + 1;
    day = dateObj.getDate();
  } else {
  }

  const [likeStatus, setLikeStatus] = useState(false);
  const [likeNum, setLikeNum] = useState(0);
  const LikeButtonHandler = async () => {
    if (likeStatus == true) {
      setLikeStatus(false);
      try {
        const url =
          process.env.REACT_APP_SERVER_API + "/nfts/dislike/" + data.nftId;
        const res = await instance.delete(url);
        if (res.status == 200) {
          setLikeNum(likeNum - 1);
        }
      } catch (error: any) {
        if (error.response.data.message) {
          WarningToastify(error.response.data.message);
          return;
        } else {
          alert("알 수 없는 에러가 발생했습니다.");
        }
      }
    } else {
      setLikeStatus(true);
      try {
        const url =
          process.env.REACT_APP_SERVER_API + "/nfts/like/" + data.nftId;
        const res = await instance.post(url);
        if (res.status == 200) {
          setLikeNum(likeNum + 1);
        }
      } catch (error: any) {
        if (error.response.data.message) {
          WarningToastify(error.response.data.message);
          return;
        } else {
          alert("알 수 없는 에러가 발생했습니다.");
        }
      }
    }
  };

  useEffect(() => {
    setLikeStatus(data.userLikesNft);
    setLikeNum(data.likeCount);
    const currencyRes: any = MappingCurrencyType(data.network);
    setCurrency(currencyRes);
  }, []);

  return (
    <div className="flex sm:flex-row flex-col w-full  sm:space-x-[80px] justify-center ">
      <div className="flex flex-col justify-center w-full max-w-[560px]  h-full">
        <LeftArrow width={36} height={36} fillColor="#868686" />
        <img src={data?.image} className="nfttype-img" />
      </div>
      <div>
        <div className="w-full flex content-between justify-between">
          <div className="nfttype-type-button ">진료 NFT</div>
          <div className="flex space-x-3">
            <div className="nft-detail-title-button">
              <EyeSVG width={24} height={24} fillColor="#ffffff" />
              <span>{data.viewCount}</span>
            </div>
            <div
              className="nft-detail-title-button cursor-pointer"
              onClick={() => {
                if (sessionStorage.getItem("login_user")) {
                  LikeButtonHandler();
                } else {
                  WarningToastify("로그인을 먼저 진행해주세요.");
                  return;
                }
              }}
            >
              {likeStatus ? (
                <FullHeartSVG width={22} height={22} fillColor="#ffffff" />
              ) : (
                <HeartSVG width={22} height={22} fillColor="#ffffff" />
              )}

              <span>{likeNum}</span>
            </div>
          </div>
        </div>

        <div className="ml-1">
          <div className="flex flex-row content-between justify-between ">
            <h3 className="nfttype-main-title">{data?.name}</h3>
          </div>
          <div className="mt-[60px]">
            <div className="flex flex-row w-full">
              <span className="nfttype-content-title">소유자 지갑주소</span>
              <div>
                {/* <span className=" text-[17px]">{data.price}</span> */}
                <div className=" w-[360px]  text-gray80">
                  {data?.sellerWalletAddress}
                </div>
              </div>
            </div>

            <div className="flex flex-row w-full mt-[50px] items-center">
              <span className="nfttype-content-title">가격</span>
              <div className=" w-[360px] overflow-hidden  flex  items-center">
                <span className="nfttype-content-price">{data?.price}</span>
                <span className="nfttype-content-price-content">
                  {currency}
                </span>
              </div>
            </div>
            <div className="nfttype-content-div">
              <span className="nfttype-content-title">설명</span>
              <span className=" text-[17px] text-gray80 w-full max-w-[400px]">
                {data?.metaData?.description}
              </span>
            </div>
            <div className="nfttype-content-div">
              <span className="nfttype-content-title">진료명</span>
              <span className="text-[17px] border border-brand100 rounded-[30px] h-[32px] px-5 flex items-center justify-center text-brand100 ">
                {data?.metaData?.clinicName}
              </span>
            </div>
            <div className="nfttype-content-div">
              <span className="nfttype-content-title">진료 일자</span>
              <div className="flex items-center">
                <span className="mr-2 text-[17px] border border-brand100 rounded-[30px] h-[32px] w-[94px] flex items-center justify-center text-brand100 ">
                  {year}
                </span>
                <span className="mr-2 text-brand100">년</span>
                <span className="mr-2 text-[17px] border border-brand100 rounded-[30px] h-[32px] w-[80px] flex items-center justify-center text-brand100 ">
                  {month}
                </span>
                <span className="mr-3  text-brand100">월</span>
                <span className="mr-2 text-[17px] border border-brand100 rounded-[30px] h-[32px] w-[80px] flex items-center justify-center text-brand100 ">
                  {day}
                </span>
                <span className="mr-2  text-brand100">일</span>
              </div>
            </div>
            <div className="nfttype-content-div">
              <span className="nfttype-content-title">진료 내용</span>
              <span className=" text-[17px] text-gray80  w-full max-w-[400px]">
                {data?.metaData?.clinicContents}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypeClinicNFT;
