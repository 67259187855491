export const SampleImageList = [
  {
    imageUrl:
      "https://images.unsplash.com/photo-1615751072497-5f5169febe17?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=735&q=80",
    title: "심각한 영양실조",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1616549105827-103e19d10c6d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80",
    title: "왼쪽 다리 기형",
  },
  {
    imageUrl:
      "https://plus.unsplash.com/premium_photo-1661892088256-0a17130b3d0d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80",
    title: "심장사상충",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1610244130620-fd348aa7854e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1469&q=80",
    title: "급성 맹장염",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1492370284958-c20b15c692d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1498&q=80",
    title: "백내장",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1615497001839-b0a0eac3274c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1587&q=80",
    title: "급성 장염",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1561948955-570b270e7c36?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1501&q=80",
    title: "오른쪽 다리 골절",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1556582305-528bffcf7af0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1287&q=80",
    title: "전염성 복막염",
  },
];

export const nftListSampleData = [
  {
    id: 1,
    imageUrl:
      "https://plus.unsplash.com/premium_photo-1661892088256-0a17130b3d0d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2080&q=80",
    title: "주인공 소리",
    price: "53.81",
    owner: "유기견협회",
  },
  {
    id: 2,
    imageUrl:
      "https://images.unsplash.com/photo-1610112645245-36020fc0e128?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
    title: "귀족 같은 로나",
    price: "233",
    owner: "유기견협회",
  },
  {
    id: 3,
    imageUrl:
      "https://images.unsplash.com/photo-1583511655826-05700d52f4d9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1976&q=80",
    title: "몬수니 아치",
    price: "53.81",
    owner: "미모",
  },
  {
    id: 4,
    imageUrl:
      "https://images.unsplash.com/photo-1615497001839-b0a0eac3274c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
    title: "미미 미모견",
    price: "58.26",
    owner: "홍길동",
  },
  {
    id: 5,
    imageUrl:
      "https://images.unsplash.com/photo-1587402092301-725e37c70fd8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1964&q=80",
    title: "라라 귀요미",
    price: "300",
    owner: "왓콘보호소",
  },
  {
    id: 6,
    imageUrl:
      "https://images.unsplash.com/photo-1578339850459-76b0ac239aa2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2063&q=80",
    title: "동수의 긴털",
    price: "152",
    owner: "팅커벨",
  },
  {
    id: 7,
    imageUrl:
      "https://images.unsplash.com/photo-1554692936-82776f9406db?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
    title: "해든이",
    price: "122.23",
    owner: "피터팬보호소",
  },
  {
    id: 8,
    imageUrl:
      "https://images.unsplash.com/flagged/photo-1557427161-4701a0fa2f42?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
    title: "귀여운 로로",
    price: "96.23",
    owner: "유기견협회",
  },
  {
    id: 9,
    imageUrl:
      "https://images.unsplash.com/photo-1555325084-b068599743c9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1950&q=80",
    title: "사랑스러운 나비",
    price: "59.63",
    owner: "아산동물병원",
  },
  {
    id: 10,
    imageUrl:
      "https://images.unsplash.com/photo-1562176564-0280c730d87c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
    title: "깜찍한 미미",
    price: "19.52",
    owner: "나비동물병원",
  },
  {
    id: 11,
    imageUrl:
      "https://images.unsplash.com/photo-1591906157765-c67d1162983a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
    title: "귀여운 로든이",
    price: "155.32",
    owner: "유기견협회",
  },
  {
    id: 12,
    imageUrl:
      "https://images.unsplash.com/photo-1565209704209-6a3a0119bcbb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
    title: "예쁜 투투",
    price: "795.33",
    owner: "유기견보호소",
  },
  {
    id: 13,
    imageUrl:
      "https://images.unsplash.com/photo-1622473838954-c92561514d23?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2127&q=80",
    title: "사랑스러운 풍풍",
    price: "122.25",
    owner: "한국유기견협회",
  },
  {
    id: 14,
    imageUrl:
      "https://images.unsplash.com/photo-1534521304921-99d19068d8aa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
    title: "갑분쑥 등장",
    price: "155.45",
    owner: "유기묘보호소",
  },
  {
    id: 15,
    imageUrl:
      "https://images.unsplash.com/photo-1575964479108-1b48ca7bf171?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1935&q=80",
    title: "등수의 긴털",
    price: "100",
    owner: "동물단체",
  },
  {
    id: 16,
    imageUrl:
      "https://images.unsplash.com/photo-1615751072497-5f5169febe17?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1935&q=80",
    title: "우아한 크림이",
    price: "120",
    owner: "유기견협회",
  },
];
