import Caver from "caver-js";

const KlaytnSetProvider = (provider: any) => {
  try {
    const chainId = [1001, 8217, 1004];
    if (provider !== null) {
      if (chainId.includes(Number(provider.networkVersion))) {
        if (typeof provider.selectedAddress !== "undefined") {
          const caver = new Caver(provider);
          return { success: true, provider: caver.klay };
        } else {
          return { success: false, Error: "No login information." };
        }
      }
    }
  } catch (err) {
    console.log("provider error:", err);
    return { success: false, Error: err };
  }
};

export default KlaytnSetProvider;
