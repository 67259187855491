import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Carousel } from "flowbite-react";
import BuyNFT from "../../../Feat/nft/BuyNFT";
import axios from "axios";
import Modal from "../../common/Modal";
import IsBuyingModalContent from "./IsBuyingModalContent";
import NotiToastify from "../../common/NotiToastify";
import CheckCurrentNetwork from "../../../Feat/network/CheckCurrentNetwork";
import MatchChainIdAndNetworkName from "../../../Feat/network/MatchChainIdAndNetworkName";
import WarningToastify from "../../common/WarningToastify";
import KlaytnBuyingNFT from "../../../Feat/nft/klaytn/KlaytnBuyingNFT";
import TypeLifeNFT from "../nftType/TypeLifeNFT";
import TypeRegisterNFT from "../nftType/TypeRegisterNFT";
import TypeClinicNFT from "../nftType/TypeClinicNFT";
import TypeAdoptNFT from "../nftType/TypeAdoptNFT";
import TypeDonationNFT from "../nftType/TypeDonationNFT";
import instance from "../../../Feat/axios/instance";
import GetRelatedNFTInfo from "../../../Feat/nft/GetRelatedNFTInfo";
import ConnectKaikas from "../../../Feat/wallet/klaytn/ConnectKaikas";
import ValidateLoginWalletList from "../../../Feat/wallet/ValidateLoginWalletList";
import { ConnectMetaMask } from "../../../Feat/wallet/ConnectMetaMask";
import RelatedNftCard from "./RelatedNftCard";
import GetRelatedAsUserInfo from "../../../Feat/nft/GetRelatedAsUserInfo";
import CheckCurrentKlaytnNetwork from "../../../Feat/wallet/klaytn/CheckCurrentKlaytnNetwork";

const NftDetailContainer = () => {
  const { id } = useParams();
  const [data, setData]: any = useState([]);
  const [loginStatus, setLoginStatus]: any = useState("GUEST");
  const [isActiveBuying, setIsActiveBuying] = useState(false);
  const [nftType, setNftType] = useState("");
  const [isActiveBuyingButton, setIsActiveButton] = useState(false);
  const [nftIdGalleryList, setNftIdGalleryList] = useState([]);
  const [detectUpdate, setDetectUpdate] = useState(false);

  const customTheme = {
    root: {
      leftControl:
        "absolute top-[110px] left-[-60px] flex items-center justify-center px-4 focus:outline-none  main-left-controller_emergency",
      rightControl:
        "absolute top-[110px]  right-[-60px] flex items-center justify-center px-4 focus:outline-none main-right-controller_emergency",
    },
  };

  const imageGroups = Array.from(
    { length: Math.ceil(nftIdGalleryList.length / 5) },
    (_, groupIndex) =>
      nftIdGalleryList.slice(groupIndex * 5, (groupIndex + 1) * 5)
  );

  const fetchNftIdDetailNftInfo = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_API + "/nfts/info/" + id + "?myPage=false";
      const res = await axios.get(url);
      setData(res.data.data);
      setNftType(res.data.data.nftType);

      const relatedNFTResponse: any = await GetRelatedNFTInfo(
        res.data.data.nftId,
        0
      );

      setNftIdGalleryList(relatedNFTResponse.data);

      return true;
    } catch (error) {
      alert("잘못된 네트워크 요청입니다.");
      window.location.href = "/nftList";
      return false;
    }
  };

  const fetchNftIdDetailNftInfoAsLoginUser = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_API + "/nfts/info/" + id + "?myPage=false";
      const res = await instance.get(url);
      setData(res.data.data);
      setNftType(res.data.data.nftType);

      const relatedNFTResponse: any = await GetRelatedAsUserInfo(
        res.data.data.nftId,
        0
      );
      setNftIdGalleryList(relatedNFTResponse.data);
      return true;
    } catch (error) {
      alert("잘못된 네트워크 요청입니다.");
      window.location.href = "/nftList";
      return false;
    }
  };

  const metaMaskBuying = async () => {
    const currentNetwork = await CheckCurrentNetwork();
    const networkName = MatchChainIdAndNetworkName(currentNetwork);
    if (networkName == data.network && data.network !== "BAOBAB") {
      if (data !== null) {
        if (
          data &&
          data.auctionNumber &&
          data.price &&
          data.nftId &&
          data.saleId
        ) {
          const currentWalletAddress = await ConnectMetaMask();
          const validateLoginStatus = await ValidateLoginWalletList(
            currentWalletAddress
          );
          if (!validateLoginStatus) {
            WarningToastify("가입 시 등록한 지갑으로 연결해주세요.");
            return;
          }
          setIsActiveBuying(true);
          try {
            const result = await BuyNFT(
              data.auctionNumber,
              data.price,
              data.nftId,
              data.saleId
            );
            if (result) {
              NotiToastify("구매가 완료되었습니다.");
              setIsActiveBuying(false);
              window.location.href = "/nftList";
            } else {
              NotiToastify("구매에 실패했습니다. ");
              setIsActiveBuying(false);
            }
          } catch (error: any) {
            // console.log("error:", error);
          }
        } else {
          NotiToastify("구매에 실패했습니다.");
          setIsActiveBuying(false);
          return;
        }
      }
    } else if (data.network == "BAOBAB") {
      WarningToastify(
        <div>
          현재 NFT 는
          <br /> 카이카스로만 구매하실 수 있습니다.
        </div>
      );
    } else {
      WarningToastify(`현재 연결하신 네트워크를 사용할 수 없습니다.`);
      return;
    }
  };

  const kaikasBuying = async () => {
    const userStatusString: any = sessionStorage.getItem("login_user");
    const userStatus = JSON.parse(userStatusString);
    if (
      data.network == "BAOBAB" ||
      (data.network == "WHATCON" && userStatus.walletType == "KAIKAS")
    ) {
      if (data !== null) {
        if (
          data &&
          data.auctionNumber &&
          data.price &&
          data.nftId &&
          data.saleId
        ) {
          const currentWalletAddress = await ConnectKaikas();
          const validateLoginStatus = await ValidateLoginWalletList(
            currentWalletAddress
          );
          if (!validateLoginStatus) {
            WarningToastify("가입 시 등록한 지갑으로 연결해주세요.");
            return;
          }
          const { chainId }: any = await CheckCurrentKlaytnNetwork();
          const networkName: any = MatchChainIdAndNetworkName(chainId);

          if (data.network == "BAOBAB" && networkName == "WHATCON") {
            WarningToastify("바오밥 네트워크로 연결해주세요!");
            return;
          } else if (data.network == "WHATCON" && networkName == "BAOBAB") {
            WarningToastify("왓콘 네트워크로 연결해주세요!");
            return;
          }

          setIsActiveBuying(true);
          const result = await KlaytnBuyingNFT(
            data.auctionNumber,
            data.price,
            data.nftId,
            data.saleId,
            networkName
          );
          if (result) {
            NotiToastify("구매가 완료되었습니다.");
            setIsActiveBuying(false);
            window.location.href = "/nftList";
          } else {
            NotiToastify("구매에 실패했습니다.");
            setIsActiveBuying(false);
          }
        } else {
          NotiToastify("구매에 실패했습니다.");
          setIsActiveBuying(false);
          return;
        }
      }
    } else if (userStatus.walletType == "METAMASK") {
      WarningToastify(
        <div>
          해당 NFT는 <br />
          카이카스 지갑으로 구매 가능합니다.
        </div>
      );
      return;
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("login_user") !== null) {
      fetchNftIdDetailNftInfoAsLoginUser();
      const userStatusString: any = sessionStorage.getItem("login_user");
      const userStatus = JSON.parse(userStatusString);
      if (userStatus.type == "USER") {
        setLoginStatus("USER");
      } else if (userStatus.type == "RIGHTS_GROUP") {
        setLoginStatus("RIGHTS_GROUP");
      } else if (userStatus.type == "FEED_COMPANY") {
        setLoginStatus("FEED_COMPANY");
      }
    } else {
      fetchNftIdDetailNftInfo();
    }
  }, [detectUpdate]);

  const detectValidateBuying = async () => {
    if (sessionStorage.getItem("login_user")) {
      const userStatusString: any = sessionStorage.getItem("login_user");
      const userStatus = JSON.parse(userStatusString);
      if (data.sellerWalletAddress !== userStatus.loginAddress) {
        if (data.nftType) {
          if (nftType == "DONATION" && userStatus.type == "RIGHTS_GROUP") {
            setIsActiveButton(true);
          } else if (nftType !== "DONATION" && userStatus.type == "USER") {
            setIsActiveButton(true);
          } else {
            setIsActiveBuying(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    detectValidateBuying();
  }, [data]);

  return (
    <div className="w-full flex items-center justify-center flex-col">
      <Modal
        content={<IsBuyingModalContent />}
        isActiveModal={isActiveBuying}
        closeModal={setIsActiveBuying}
      />
      <div className="flex flex-col items-center mt-[80px] relative w-[90%]">
        {nftType == "LIFESTYLE" && <TypeLifeNFT data={data} />}
        {nftType == "REGISTRATION" && <TypeRegisterNFT data={data} />}
        {nftType == "MEDICAL" && <TypeClinicNFT data={data} />}
        {nftType == "ADOPTION" && <TypeAdoptNFT data={data} />}
        {nftType == "DONATION" && <TypeDonationNFT data={data} />}

        <div className="w-full flex items-center justify-center mb-[120px] mt-[60px]">
          {loginStatus !== "FEED_COMPANY" && (
            <div className="flex flex-col w-full items-center justify-center">
              <div className="text-center p-4 rounded-md text-[16px] bg-brand100 bg-opacity-10">
                현재 보고계신 NFT는
                <span className="text-[#470B11]  font-semibold px-2">
                  {data?.network}
                </span>
                네트워크를 사용하고 있습니다.
                <br />
                NFT를 구매하기 위해서
                <span className="text-[#470B11]  font-semibold px-2">
                  {data?.network}
                </span>
                로 연결되어있는지 확인해주세요
              </div>

              {isActiveBuyingButton && (
                <button
                  type="button"
                  className="bg-[#E06612] mt-[40px]  h-[46px] w-[160px] font-semibold text-[22px] text-white py-1 rounded-[30px] flex items-center justify-center"
                  onClick={() => {
                    const userStatusString: any =
                      sessionStorage.getItem("login_user");
                    const userStatus = JSON.parse(userStatusString);
                    console.log(userStatus.walletType);
                    if (
                      (userStatus.walletType == "METAMASK" &&
                        data.network == "SEPOLIA") ||
                      data.network == "BNB" ||
                      data.network == "CRONOS"
                    ) {
                      metaMaskBuying();
                    } else if (
                      (userStatus.walletType == "KAIKAS" &&
                        data.network == "BAOBAB") ||
                      data.network == "WHATCON"
                    ) {
                      kaikasBuying();
                    } else if (
                      (userStatus.walletType == "KAIKAS" &&
                        data.network == "SEPOLIA") ||
                      data.network == "BNB" ||
                      data.network == "CRONOS"
                    ) {
                      WarningToastify(
                        "현재 NFT는 메타마스크 지갑으로 구매할 수 있습니다."
                      );
                    } else if (
                      userStatus.walletType == "METAMASK" &&
                      data.network == "BAOBAB"
                    ) {
                      WarningToastify(
                        "현재 NFT는 카이카스 지갑으로 구매할 수 있습니다."
                      );
                    }
                  }}
                >
                  결제하기
                </button>
              )}
            </div>
          )}
        </div>
        <div className="w-full flex items-center justify-center mb-[120px]">
          <div className="w-full">
            {nftIdGalleryList.length >= 1 && (
              <div className="flex items-center justify-center flex-col">
                <h3 className="text-[32px] font-bold w-full mb-6">
                  이 동물의 다른 NFT
                </h3>

                <div className="flex h-[340px] w-full flex-col">
                  <Carousel theme={customTheme}>
                    {imageGroups.map((imageGroup, groupIndex) => (
                      <div className="flex space-x-3 h-full" key={groupIndex}>
                        {imageGroup.map((imageInfo: any, index) => {
                          return (
                            <div key={index}>
                              <RelatedNftCard
                                item={imageInfo}
                                key={index}
                                setDetectUpdate={setDetectUpdate}
                                detectUpdate={detectUpdate}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftDetailContainer;
