import { useRecoilState } from "recoil";
import six_steps_logo from "../../../../common/images/6_steps.jpeg";
import { signUpUserState } from "../../../../utils/recoil/auth/register/atom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NotiToastify from "../../common/NotiToastify";

const RegisterAgree = () => {
  const [adAgreed, setAdAgreed] = useState(false);
  const [signUpUser, setSignUpUser] = useRecoilState(signUpUserState);
  const [requiredCheck1, setRequiredCheck1] = useState(false);
  const [requiredCheck2, setRequiredCheck2] = useState(false);
  const navigate = useNavigate();

  const handleAdAgreementChange = (checked: any) => {
    setAdAgreed(checked);

    // Update the signUpUserState's adAgreed property.
    setSignUpUser((prevSignUpUser) => ({
      ...prevSignUpUser,
      adAgreed: checked,
    }));
  };

  const handleConfirmClick = () => {
    if (!requiredCheck1 || !requiredCheck2) {
      NotiToastify("필수 동의란에 체크해 주세요!");
      return;
    } else {
      navigate("/register/main");
    }
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  useEffect(() => {
    if (sessionStorage.getItem("login_user")) {
      alert("이미 로그인된 상태입니다.");
      window.location.href = "/";
    }
  }, []);

  return (
    <div className="flex items-center justify-center ">
      <div className="flex items-center justify-center flex-col mt-[50px] w-[80%] ">
        <div className="w-ful items-center justify-center flex flex-col">
          <img src={six_steps_logo} className="w-[120px] h-[105px]" />
          <h3 className="text-gray100 mt-[20px]  text-[20px]">
            회원 약관 동의
          </h3>
        </div>

        {/* 이용약관 동의(필수) 1 */}
        <div className="flex flex-col mt-[100px]">
          <div className="flex content-between justify-between">
            <h3 className="text-[22px] text-brand100 mb-3">
              이용약관 동의(필수)
            </h3>
            <div>
              <div className="flex items-center text-brand100">
                <input
                  type="checkbox"
                  checked={requiredCheck1}
                  onChange={() => setRequiredCheck1(!requiredCheck1)}
                  className="mr-2 border border-brand100 rounded-full checked:bg-brand100"
                />
                <h3 className="mr-2 text-[18px]">동의함 </h3>

                <input
                  type="checkbox"
                  checked={!requiredCheck1}
                  onChange={() => setRequiredCheck1(!requiredCheck1)}
                  className="mr-1 ml-2 border border-brand100 rounded-full checked:bg-brand100"
                />
                <h3 className="text-brand100 text-[18px] ">동의하지 않음</h3>
              </div>
            </div>
          </div>
          <div className="border border-brand100 w-full  h-[200px] rounded-[10px] text-gray80 p-5 overflow-auto">
            제1조(목적)
            <br /> 본 약관은 공유누리 (이하 “당 사이트”)이 제공하는 모든
            서비스(이하 “서비스”)의 이용조건 및 절차, 이용자와 당 사이트의 권리,
            의무, 책임사항과 기타 필요한 사항을 규정함을 목적으로 합니다. <br />{" "}
            <br />
            제2조(용어의 정의) <br /> 본 약관에서 사용하는 용어의 정의는 다음과
            같습니다. <br /> 1. 이용자 : 본 약관에 따라 당 사이트가 제공하는
            서비스를 이용할 수 있는 자.
          </div>
        </div>

        {/* 이용약관 동의(필수) 2 */}
        <div className="flex flex-col ">
          <div className="flex content-between justify-between mt-[50px]">
            <h3 className="text-[22px] text-brand100 mb-3 ">
              개인정보 수집 및 이용 동의(필수)
            </h3>
            <div>
              <div className="flex items-center justify-center text-brand100">
                <input
                  type="checkbox"
                  checked={requiredCheck2}
                  onChange={() => setRequiredCheck2(!requiredCheck2)}
                  className="mr-2 border border-brand100 rounded-full checked:bg-brand100"
                />
                <h3 className="mr-1 text-[18px]">동의함 </h3>

                <input
                  type="checkbox"
                  checked={!requiredCheck2}
                  onChange={() => setRequiredCheck2(!requiredCheck2)}
                  className="mr-1 ml-2 border border-brand100 rounded-full checked:bg-brand100"
                />
                <h3 className="text-brand100 text-[18px]">동의하지 않음</h3>
              </div>
            </div>
          </div>
          <div className="border border-brand100 w-full h-[200px] rounded-[10px] text-gray80 p-5 overflow-auto">
            제1조(목적)
            <br /> 본 약관은 공유누리 (이하 “당 사이트”)이 제공하는 모든
            서비스(이하 “서비스”)의 이용조건 및 절차, 이용자와 당 사이트의 권리,
            의무, 책임사항과 기타 필요한 사항을 규정함을 목적으로 합니다.
            <br />
            <br />
            제2조(용어의 정의)
            <br /> 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            <br /> 1. 이용자 : 본 약관에 따라 당 사이트가 제공하는 서비스를
            이용할 수 있는 자.
          </div>
        </div>

        {/* 이용약관 동의(선택) */}
        <div className="flex flex-col ">
          <div className="flex content-between justify-between mt-[50px]">
            <h3 className="text-[22px] text-brand100 mb-3">
              프로모션 정보 수신 동의(선택)
            </h3>
            <div>
              <div className="flex items-center text-brand100">
                <input
                  type="checkbox"
                  checked={adAgreed}
                  onChange={(e) => handleAdAgreementChange(e.target.checked)}
                  className="mr-2 border border-brand100 rounded-full checked:bg-brand100"
                />
                <h3 className="mr-1 text-[18px]">동의함 </h3>

                <input
                  type="checkbox"
                  checked={!adAgreed}
                  onChange={(e) => handleAdAgreementChange(!e.target.checked)}
                  className="mr-1 ml-2 border border-brand100 rounded-full checked:bg-brand100"
                />
                <h3 className="text-brand100 text-[18px] ">동의하지 않음</h3>
              </div>
            </div>
          </div>
          <div className="border border-brand100 w-full sm:max-w-[1260px] h-[200px] rounded-[10px] text-gray80 p-5 overflow-auto">
            제1조(목적)
            <br /> 본 약관은 공유누리 (이하 “당 사이트”)이 제공하는 모든
            서비스(이하 “서비스”)의 이용조건 및 절차, 이용자와 당 사이트의 권리,
            의무, 책임사항과 기타 필요한 사항을 규정함을 목적으로 합니다. <br />{" "}
            <br />
            제2조(용어의 정의) <br />본 약관에서 사용하는 용어의 정의는 다음과
            같습니다. <br />
            1.이용자 : 본 약관에 따라 당 사이트가 제공하는 서비스를 이용할 수
            있는 자.
          </div>
        </div>
        <div className="flex w-full items-center justify-center space-x-5 mt-[50px] mb-[140px] ">
          <button
            type="button"
            className="border border-brand100 w-[120px]  rounded-[30px] text-brand100 h-[38px] text-[20px]"
            onClick={handleCancelClick}
          >
            취소
          </button>

          <button
            type="button"
            className=" bg-brand100 w-[120px] rounded-[30px] text-white h-[38px] text-[20px]"
            onClick={handleConfirmClick}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterAgree;
