import { useNavigate } from "react-router-dom";
import HeartSVG from "../../../../../common/svg/HeartSVG";
import EyeSVG from "../../../../../common/svg/EyeSVG";
import { useEffect, useState } from "react";
import FullHeartSVG from "../../../../../common/svg/FullHeartSVG";
import instance from "../../../../Feat/axios/instance";
import MappingCurrencyType from "../../../../Feat/common/MappingCurrencyType";
import WarningToastify from "../../../common/WarningToastify";

interface ItemType {
  nftId: number;
  image: string;
  nftType: string;
  tokenId: number;
  network: string;
  metaData: {
    age: number;
    breed: string;
    gender: "MALE" | "FEMALE";
    ageVerified: boolean;
    description: string;
    rescuedDate: string;
    rescuedLocation: string;
  };
  sellerWalletAddress: string;
  isOnSale: boolean;
  price: number | null;
  name: string;
  viewCount: number;
  likeCount: number;
  userLikesNft: boolean;
}

const UserMyNftDetailCard = (props: { item: ItemType }) => {
  const { item } = props;
  const navigate = useNavigate();
  const [likeStatus, setLikeStatus] = useState(false);
  const [likeNum, setLikeNum] = useState(0);
  const [currencyType, setCurrencyType] = useState<string | undefined>("");

  const handleCardClick = () => {
    navigate(`/user/mypage/nftDetail/${item.nftId}`, { state: { item } });
  };

  const LikeButtonHandler = async () => {
    if (likeStatus == true) {
      setLikeStatus(false);
      try {
        const url =
          process.env.REACT_APP_SERVER_API + "/nfts/dislike/" + item.nftId;
        const res = await instance.delete(url);
        if (res.status == 200) {
          setLikeNum(likeNum - 1);
        }
      } catch (error: any) {
        if (error.response.data.message) {
          WarningToastify(error.response.data.message);
          return;
        } else {
          alert("알 수 없는 에러가 발생했습니다.");
        }
      }
    } else {
      setLikeStatus(true);
      try {
        const url =
          process.env.REACT_APP_SERVER_API + "/nfts/like/" + item.nftId;
        const res = await instance.post(url);
        if (res.status == 200) {
          setLikeNum(likeNum + 1);
        }
      } catch (error: any) {
        if (error.response.data.message) {
          WarningToastify(error.response.data.message);
          return;
        } else {
          alert("알 수 없는 에러가 발생했습니다.");
        }
      }
    }
  };

  useEffect(() => {
    setLikeStatus(item.userLikesNft);
    setLikeNum(item.likeCount);
    const currencyResult: string | undefined = MappingCurrencyType(
      item.network
    );
    setCurrencyType(currencyResult);
  }, [props]);

  return (
    <div
      className={`flex flex-col h-fit w-[259px]   items-center mt-[20px] rounded-lg cursor-pointer transition-transform transform hover:scale-105 `}
    >
      <img
        onClick={() => {
          handleCardClick();
        }}
        src={item.image}
        className="sm:w-[260px] sm:h-[260px] rounded-[10px]"
      />
      <div className=" flex flex-col w-full">
        <div className="w-full  flex content-between justify-between items-center mt-[20px]">
          <div className="text-[20px] font-semibold bg-brand100 rounded-[20px] text-white w-[150px] h-[36px] flex items-center justify-center">
            {item.name}
          </div>
          <div className="flex space-x-[12px]">
            <div
              className="flex flex-col items-center"
              onClick={() => {
                if (sessionStorage.getItem("login_user")) {
                  LikeButtonHandler();
                }
              }}
            >
              {likeStatus ? (
                <FullHeartSVG width={22} height={22} fillColor="#e06612" />
              ) : (
                <HeartSVG width={22} height={22} fillColor="#e06612" />
              )}
              <span className="text-[12px] text-brand100">{likeNum}</span>
            </div>
            <div className="flex flex-col items-center">
              <EyeSVG fillColor="#e06612" width={22} height={22} />
              <span className="text-[12px] text-brand100">
                {item.viewCount}
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-centers items-center w-full h-[50px]">
          {item.price == 0 || undefined ? (
            <div className="">미판매</div>
          ) : (
            <div className="flex justify-centers items-center w-full ">
              <span className="text-[#390000] font-semibold">Price</span>
              <span className="ml-3 text-brand100 font-semibold">
                {item.price}
                {currencyType}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserMyNftDetailCard;
