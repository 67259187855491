import axios from "axios";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { signUpUserState } from "../../../utils/recoil/auth/register/atom";
import { useNavigate } from "react-router-dom";

const RedirectRegisterPage = () => {
  const [signUpUserData, setSignUpUserData] = useRecoilState(signUpUserState);
  const navigate = useNavigate();

  const requestRegister = async () => {
    const code = new URL(window.location.href).searchParams.get("code");
    const redirectURL =
      window.location.protocol +
      "//" +
      window.location.host +
      "/register/social";
    const url =
      process.env.REACT_APP_SERVER_API +
      "/auth/users/sign-up/kakao?code=" +
      code +
      "&redirectUri=" +
      redirectURL;
    try {
      const response = await axios.get(url);

      if (response.data.status == 200) {
        setSignUpUserData({
          ...signUpUserData,
          email: response.data.data.email,
        });
        navigate("/register/personal/social");
      }
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data.message);
        window.location.href = "/register";
      } else {
        alert("오류가 발생했습니다. 재시도해주세요");
        window.location.href = "/register";
      }
    }
  };

  useEffect(() => {
    requestRegister();
  }, []);

  return <div></div>;
};

export default RedirectRegisterPage;
