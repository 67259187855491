import { useNavigate } from "react-router-dom";
import NotiToastify from "../../common/NotiToastify";

const MainIntroduceRightSection = () => {
  const navigate = useNavigate();
  return (
    <div className="sm:w-[40%] h-full sm:ml-[5%] mt-[60px] sm:mt-0 ">
      <div className="flex flex-col">
        <span className="sm:text-[68px] text-[32px] font-extrabold">
          6 STEPS
        </span>
        <span className="sm:text-[48px] font-extrabold">
          반려동물 NFT 마켓플레이스
        </span>
      </div>

      <div className="flex flex-col">
        <span className="sm:mt-[73px]  font-medium sm:text-[20px]">
          세계최초 유기동물 기부 NFT 마켓플레이스
        </span>
        <span className="font-medium sm:text-[20px] mt-[34px]">
          반려동물의 네 발자국과 우리의 두 발자국이 <br />
          함께 이루어가는 세상을 꿈꿉니다.
        </span>
      </div>

      <div className="flex mt-[70px] space-x-[19px]">
        <button
          type="button"
          className="main-right-content-button bg-brand100"
          onClick={() => {
            navigate("/nftList");
          }}
        >
          NFT 보러가기
        </button>
        <button
          type="button"
          className="main-right-content-button bg-[#470A11]"
          onClick={() => {
            if (sessionStorage.getItem("login_user") == null) {
              NotiToastify("로그인을 먼저 진행해주세요");
            } else if (sessionStorage.getItem("login_user")) {
              navigate("/nftUpload");
            }
          }}
        >
          NFT 생성하기
        </button>
      </div>
    </div>
  );
};

export default MainIntroduceRightSection;
