import axios from "axios";
import instance from "../axios/instance";

const SendSellNFTInfoToServer = async (data: any) => {
  const sendToServerData = {
    sellerWalletAddress: data.sellerWalletAddress,
    dueDate: data.dueDate,
    price: data.price,
    auctionNumber: data.auctionNumber,
    saleTransactionHash: data.saleTransactionHash,
    network: data.network,
  };

  try {
    const url = process.env.REACT_APP_SERVER_API + "/nfts/sale/" + data.nftId;
    const response = await instance.post(url, sendToServerData, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    });

    return { data: response.data, result: true };
  } catch (error) {
    console.log(error);
    return { result: false };
  }
};

export default SendSellNFTInfoToServer;
