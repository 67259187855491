const CheckValidationServiceChain = (chainId: string) => {
  if (chainId == "0xaa36a7") {
    return "SEPOLIA";
  } else if (chainId == "0x19") {
    return "CRONOS";
  } else if (chainId == "0x3e9") {
    return "BAOBAB";
  } else if (chainId == "0x38") {
    return "BNB";
  } else {
    return false;
  }
};

export default CheckValidationServiceChain;
