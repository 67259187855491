import MainLayout from "./components/UI/layout/MainLayout";
import RegisterAgreePage from "./pages/auth/RegisterAgreePage";
import RegisterMainPage from "./pages/auth/RegisterMainPage";
import InstitutionRegisterPage from "./pages/auth/institution/InstitutionRegisterPage";
import EmailRegisterPage from "./pages/auth/personal/EmailRegisterPage";
import SocialRegisterPage from "./pages/auth/personal/SocialRegisterPage";
import MainPage from "./pages/main/MainPage";
import MyPage from "./pages/mypage/agency/MyPage";
import MyPageEdit from "./pages/mypage/agency/edit/MyPageEdit";
import UserMyPage from "./pages/mypage/user/UserMyPage";
import NftDetailPage from "./pages/nftDetail/mypage/agency/NftDetailPage";
import MyNftDetailPage from "./pages/nftDetail/mypage/agency/MyNftDetailPage";
import NftListPage from "./pages/nftList/NftListPage";
import NftUploadPage from "./pages/nftUpload/NftUploadPage";
import AdoptNFTPage from "./pages/nftUpload/adopt/AdoptNFTPage";
import ClinicNFTPage from "./pages/nftUpload/clinic/ClinicNFTPage";
import DonationNFTPage from "./pages/nftUpload/donation/DonationNFTPage";
import LifeNFTPage from "./pages/nftUpload/life/LifeNFTPage";
import RegisterNFTPage from "./pages/nftUpload/register/RegisterNFTPage";
import "./styles/App.css";
import { Route, Routes } from "react-router-dom";
import UserMyNftDetailPage from "./pages/nftDetail/mypage/user/UserMyNftDetailPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RegisterSuccessPage from "./pages/auth/RegisterSuccessPage";
import EventMainPage from "./pages/event/EventMainPage";
import EventDetailPage from "./pages/event/EventDetailPage";
import RedirectRegisterPage from "./pages/auth/socialLogin/RedirectRegisterPage";
import RedirectLoginPage from "./pages/auth/socialLogin/RedirectLoginPage";
import UserEditPage from "./pages/mypage/user/edit/UserEditPage";
import ScrollToTop from "./components/UI/common/ScrollToTop";
function App() {
  return (
    <div className="App">
      <MainLayout>
        <ScrollToTop />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/register/social" element={<RedirectRegisterPage />} />
          <Route path="/login/social" element={<RedirectLoginPage />} />

          <Route path="/event" element={<EventMainPage />} />
          <Route path="/event/detail/:id" element={<EventDetailPage />} />
          <Route path="/register" element={<RegisterAgreePage />} />
          <Route path="/register/main" element={<RegisterMainPage />} />
          <Route path="/register/success" element={<RegisterSuccessPage />} />
          <Route
            path="/register/personal/email"
            element={<EmailRegisterPage />}
          />
          <Route
            path="/register/personal/social"
            element={<SocialRegisterPage />}
          />
          <Route
            path="/register/institution"
            element={<InstitutionRegisterPage />}
          />

          <Route path="/nftList" element={<NftListPage />} />
          <Route path="/nftUpload" element={<NftUploadPage />} />
          <Route path="/nftUpload/register" element={<RegisterNFTPage />} />
          <Route path="/nftUpload/adopt" element={<AdoptNFTPage />} />
          <Route path="/nftUpload/clinic" element={<ClinicNFTPage />} />
          <Route path="/nftUpload/donation" element={<DonationNFTPage />} />
          <Route path="/nftUpload/life" element={<LifeNFTPage />} />
          <Route path="/nftDetail/:id" element={<NftDetailPage />} />
          <Route path="/user/mypage" element={<UserMyPage />} />
          <Route path="/user/mypage/edit" element={<UserEditPage />} />
          <Route path="/agency/mypage" element={<MyPage />} />
          <Route path="/agency/mypage/edit" element={<MyPageEdit />} />
          <Route
            path="/agency/mypage/nftDetail/:id"
            element={<MyNftDetailPage />}
          />
          <Route
            path="/user/mypage/nftDetail/:id"
            element={<UserMyNftDetailPage />}
          />
        </Routes>
      </MainLayout>
    </div>
  );
}

export default App;
