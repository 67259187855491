const MatchChainIdAndContractAdd = (currentNetwork: string) => {
  if (currentNetwork == "0xaa36a7") {
    return process.env.REACT_APP_STANDARD_SEPOLIA_CONTRACT_ADDRESS;
  } else if (currentNetwork == "0x19") {
    return process.env.REACT_APP_STANDARD_CRONOS_CONTRACT_ADDRESS;
  } else if (currentNetwork == "0x3e9") {
    return process.env.REACT_APP_STANDARD_BAOBAB_CONTRACT_ADDRESS;
  } else if (currentNetwork == "0x38") {
    return process.env.REACT_APP_STANDARD_BNB_CONTRACT_ADDRESS;
  } else if (currentNetwork == "1004") {
    return process.env.REACT_APP_ROUTER_WHATCON_CONTRACT_ADDRESS;
  }
};

export default MatchChainIdAndContractAdd;
