import KlaytnSetProvider from "./KlaytnSetProvider";
const nft_abi = require("../../../../utils/contract/StandardNFT.json");

const KlaytnIsApprovalForAll = async (to: any, networkName: string) => {
  let nft_contract: any;
  console.log(networkName);

  if (networkName == "BAOBAB") {
    nft_contract = process.env.REACT_APP_KLAYTN_NFT_CONTRACT;
  } else if (networkName == "WHATCON") {
    nft_contract = process.env.REACT_APP_WHATCON_NFT_CONTRACT;
  }
  console.log("KlaytnIsApprovalForAll nft_contract", nft_contract);
  try {
    const provider = await window.klaytn;
    const setP: any = await KlaytnSetProvider(provider);
    if (!setP.success) {
      return new Error(setP.Error);
    }
    const p = setP.provider;
    const account = (await p.getAccounts())[0];
    const contract = new p.Contract(nft_abi.abi, nft_contract);
    contract.options.gas = 5000000;
    const result = await contract.methods.isApprovedForAll(account, to).call();
    return { success: true, data: { isApproved: result } };
  } catch (err: any) {
    return new Error(err);
  }
};

export default KlaytnIsApprovalForAll;
