import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GetUserInfo from "../../../../Feat/user/GetUserInfo";
import Modal from "../../../common/Modal";
import WritePriceAndDateBuyModalContent from "./WritePriceAndDateBuyModalContent";
import WithdrawNFT from "../../../../Feat/nft/WithdrawNFT";
import IsWithdrawModalContent from "./IsWithdrawModalContent";
import NotiToastify from "../../../common/NotiToastify";
import WarningToastify from "../../../common/WarningToastify";
import KlaytnWithdraw from "../../../../Feat/nft/klaytn/KlaytnWithdraw";
import TypeLifeNFT from "../../nftType/TypeLifeNFT";
import TypeRegisterNFT from "../../nftType/TypeRegisterNFT";
import TypeClinicNFT from "../../nftType/TypeClinicNFT";
import TypeAdoptNFT from "../../nftType/TypeAdoptNFT";
import TypeDonationNFT from "../../nftType/TypeDonationNFT";
import GetDetailInfoUseNFTIdToServerAsLogin from "../../../../Feat/nft/GetDetailInfoUseNFTIdToServerAsLogin";
import ConnectKaikas from "../../../../Feat/wallet/klaytn/ConnectKaikas";
import ValidateLoginWalletList from "../../../../Feat/wallet/ValidateLoginWalletList";
import { ConnectMetaMask } from "../../../../Feat/wallet/ConnectMetaMask";
import CloseSVG from "../../../../../common/svg/CloseSVG";
import CheckCurrentNetwork from "../../../../Feat/network/CheckCurrentNetwork";
import MatchChainIdAndNetworkName from "../../../../Feat/network/MatchChainIdAndNetworkName";
import CheckCurrentKlaytnNetwork from "../../../../Feat/wallet/klaytn/CheckCurrentKlaytnNetwork";

const MyPageNftDetail = () => {
  const id: any = useParams();
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [detailData, setDetailData]: any = useState([]);
  const [saleId, setSaleId] = useState(0);
  const [tokenId, setTokenId] = useState("");
  const [nftId, setNftId] = useState("");
  const [isActiveWithdraw, setIsActiveWithdraw] = useState(false);
  const [nftType, setNftType] = useState("");
  const [isSeller, setIsSeller] = useState(false);

  const checkValidateSellerAddress = async (sellerWalletAddress: string) => {
    const userStatusString: any = sessionStorage.getItem("login_user");
    const userStatus = JSON.parse(userStatusString);
    if (userStatus.walletType == "METAMASK") {
      const currentWallet = await ConnectMetaMask();
      if (sellerWalletAddress == currentWallet) {
        return true;
      } else {
        return false;
      }
    } else if (userStatus.walletType == "KAIKAS") {
      const currentWallet = await ConnectKaikas();
      if (sellerWalletAddress == currentWallet) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getInfo = async () => {
    const requestNftId = id.id;
    const res: any = await GetDetailInfoUseNFTIdToServerAsLogin(requestNftId);
    setDetailData(res);
    setNftType(res.nftType);
    setTokenId(res.tokenId);
    setNftId(res.nftId);
    setSaleId(res.saleId);
    const response: any = await checkValidateSellerAddress(
      res.sellerWalletAddress
    );
    setIsSeller(response);
    return true;
  };

  useEffect(() => {
    if (sessionStorage.getItem("login_user") !== null) {
      try {
        getInfo();
      } catch (error: any) {
        // console.log("error:", error);
      }
    } else {
      alert("로그인을 먼저 진행해주세요");
      window.location.href = "/";
      return;
    }
  }, []);

  return (
    <div className="flex flex-col items-center mt-[80px] relative ">
      <Modal
        content={
          <WritePriceAndDateBuyModalContent
            props={{ tokenId, nftId, detailData }}
          />
        }
        isActiveModal={isActiveModal}
        closeModal={setIsActiveModal}
      />
      <Modal
        content={<IsWithdrawModalContent />}
        isActiveModal={isActiveWithdraw}
        closeModal={setIsActiveWithdraw}
      />

      {nftType == "LIFESTYLE" && <TypeLifeNFT data={detailData} />}
      {nftType == "REGISTRATION" && <TypeRegisterNFT data={detailData} />}
      {nftType == "MEDICAL" && <TypeClinicNFT data={detailData} />}
      {nftType == "ADOPTION" && <TypeAdoptNFT data={detailData} />}
      {nftType == "DONATION" && <TypeDonationNFT data={detailData} />}

      {detailData.isOnSale == false && isSeller && (
        <div className="mb-[140px] mt-[20px]">
          <div
            onClick={async () => {
              const userStatusString: any =
                sessionStorage.getItem("login_user");
              const userStatus = JSON.parse(userStatusString);

              if (
                detailData.network == "BAOBAB" ||
                (detailData.network == "WHATCON" &&
                  userStatus.walletType == "KAIKAS")
              ) {
                if (detailData.sellerWalletAddress == userStatus.loginAddress) {
                  if (
                    userStatus.type !== "FEED_COMPANY" &&
                    detailData.nftType == "DONATION"
                  ) {
                    WarningToastify("기부 NFT는 판매할 수 없습니다.");
                    return;
                  }
                  const currentWallet = await ConnectKaikas();
                  const validateWalletStatus = await ValidateLoginWalletList(
                    currentWallet
                  );
                  if (!validateWalletStatus) {
                    WarningToastify("등록된 지갑주소로 연결해주세요.");
                    return;
                  }
                  const chainId: any = await CheckCurrentKlaytnNetwork();
                  const currentNetworkName = MatchChainIdAndNetworkName(
                    chainId.chainId
                  );

                  if (!currentNetworkName) {
                    WarningToastify("현재 제공하는 네트워크로 연결해주세요.");
                    return;
                  }

                  if (currentNetworkName == detailData.network) {
                    setIsActiveModal(true);
                  } else {
                    WarningToastify("해당 NFT의 네트워크로 접속해주세요.");
                    return;
                  }
                } else if (userStatus.walletType == "KAIKAS") {
                  WarningToastify("현재 NFT는 카이카스로 판매할 수 있습니다.");
                }
              } else if (
                detailData.network == "SEPOLIA" ||
                detailData.network == "BNB" ||
                detailData.network == "CRONOS"
              ) {
                if (
                  userStatus.walletType == "METAMASK" &&
                  detailData.sellerWalletAddress == userStatus.loginAddress
                ) {
                  if (
                    userStatus.type !== "FEED_COMPANY" &&
                    detailData.nftType == "DONATION"
                  ) {
                    WarningToastify("기부 NFT는 판매할 수 없습니다.");
                    return;
                  }
                  const currentWallet = await ConnectMetaMask();
                  const validateWalletStatus = await ValidateLoginWalletList(
                    currentWallet
                  );
                  if (!validateWalletStatus) {
                    WarningToastify("등록된 지갑주소로 연결해주세요.");
                    return;
                  }
                  const chainId: any = await CheckCurrentNetwork();
                  const currentNetworkName =
                    MatchChainIdAndNetworkName(chainId);

                  if (!currentNetworkName) {
                    WarningToastify("현재 제공하는 네트워크로 연결해주세요.");
                    return;
                  }

                  if (currentNetworkName == detailData.network) {
                    setIsActiveModal(true);
                  } else {
                    WarningToastify("해당 NFT의 네트워크로 접속해주세요.");
                    return;
                  }
                } else if (userStatus.walletType == "KAIKAS") {
                  WarningToastify(
                    "현재 NFT는 메타마스크로 판매할 수 있습니다."
                  );
                }
              }
            }}
            className="mt-[80px] bg-brand100 text-white rounded-[30px] w-[146px] flex items-center justify-center h-[46px] text-[22px] cursor-pointer"
          >
            판매하기
          </div>
        </div>
      )}

      {detailData.isOnSale == true && isSeller && (
        <div
          onClick={async () => {
            if (detailData.auctionNumber !== "" && saleId !== 0) {
              const userStatusString: any =
                sessionStorage.getItem("login_user");
              const userStatus = JSON.parse(userStatusString);
              if (
                detailData.network == "BAOBAB" ||
                detailData.network == "WHATCON"
              ) {
                if (
                  userStatus.walletType == "KAIKAS" &&
                  detailData.sellerWalletAddress == userStatus.loginAddress
                ) {
                  const walletAddress = await ConnectKaikas();
                  if (detailData.sellerWalletAddress !== walletAddress) {
                    WarningToastify("민팅한 지갑주소로 취소해주세요.");
                    return;
                  }

                  const chainId: any = await CheckCurrentKlaytnNetwork();
                  const currentNetworkName = MatchChainIdAndNetworkName(
                    chainId.chainId
                  );

                  if (!currentNetworkName) {
                    WarningToastify("제공하는 네트워크로 연결해주세요");
                    return;
                  }
                  if (currentNetworkName == detailData.network) {
                    setIsActiveWithdraw(true);
                    const { chainId }: any = await CheckCurrentKlaytnNetwork();
                    const networkName: any =
                      MatchChainIdAndNetworkName(chainId);
                    const res: any = KlaytnWithdraw(
                      detailData.auctionNumber,
                      saleId,
                      networkName
                    );
                    res.then((item: any) => {
                      if (item.success) {
                        setIsActiveWithdraw(false);
                        NotiToastify("취소가 완료되었습니다.");
                        window.location.href = "/agency/mypage";
                      } else {
                        setIsActiveWithdraw(false);
                        NotiToastify("NFT 판매취소 실패");
                        return;
                      }
                    });
                  } else {
                    WarningToastify("민팅된 NFT의 네트워크로 연결해주세요.");
                    return;
                  }
                } else if (userStatus.walletType == "KAIKAS") {
                  WarningToastify("현재 NFT는 카이카스로 취소할 수 있습니다.");
                }
              } else if (
                detailData.network == "SEPOLIA" ||
                detailData.network == "BNB" ||
                detailData.network == "CRONOS"
              ) {
                if (userStatus.walletType == "METAMASK") {
                  const walletAddress = await ConnectMetaMask();
                  if (detailData.sellerWalletAddress !== walletAddress) {
                    WarningToastify("민팅한 지갑주소로 취소해주세요.");
                    return;
                  }

                  const chainId: any = await CheckCurrentNetwork();
                  const currentNetworkName =
                    MatchChainIdAndNetworkName(chainId);

                  if (!currentNetworkName) {
                    WarningToastify("현재 제공하는 네트워크로 연결해주세요.");
                    return;
                  }

                  if (currentNetworkName == detailData.network) {
                    setIsActiveWithdraw(true);
                    const res = WithdrawNFT(detailData.auctionNumber, saleId);
                    res.then((res) => {
                      if (res) {
                        setIsActiveWithdraw(false);
                        NotiToastify("취소가 완료되었습니다.");
                        window.location.href = "/agency/mypage";
                      }
                    });
                  } else {
                    WarningToastify("민팅된 NFT의 네트워크로 연결해주세요.");
                    return;
                  }
                } else if (userStatus.walletType == "KAIKAS") {
                  WarningToastify(
                    "현재 NFT는 메타마스크로 취소할 수 있습니다."
                  );
                }
              }
            } else {
              alert("값을 확인해주세요 ");
            }
          }}
          className="mt-[80px] mb-[140px]  bg-brand100 text-white rounded-[30px] w-[146px] flex items-center justify-center h-[46px] text-[22px] cursor-pointer"
        >
          판매취소
        </div>
      )}
      {!isSeller && (
        <div className="my-[120px] flex flex-col  items-center justify-center text-gray-400">
          <span>
            현재 연결하신 지갑주소가 발행된 지갑주소와 일치하지 않습니다.
          </span>

          <div className="mt-[5px]">
            판매를 하기 위해
            <span className="bg-brand100 text-white px-1 rounded-sm ml-1">
              NFT 생성시 사용하신 지갑 주소로 로그인
            </span>
            해주세요.
          </div>
          <span></span>
        </div>
      )}
    </div>
  );
};

export default MyPageNftDetail;
