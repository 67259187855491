import React from "react";
import whatcon from "../../../common/images/whatcon_logo_image.png";
import InstagramSVG from "../../../common/svg/InstagramSVG";
import FacebookSVG from "../../../common/svg/FacebookSVG";
import DiscordSVG from "../../../common/svg/DiscordSVG";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="text-gray-600 body-font h-[90px] flex border-gray-100 border shadow-md w-full">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        {/* <div className="space-x-10 sm:flex hidden">
          <DiscordSVG width={28} height={28} fillColor="#606060" />
          <InstagramSVG width={28} height={28} fillColor="#606060" />
          <FacebookSVG width={28} height={28} fillColor="none" />
        </div> */}

        <nav className=" md:mr-auto flex flex-wrap items-center text-base justify-center">
          <div className=" w-full flex">
            <span className="text-[24px] font-bold text-black flex">
              <img src={whatcon} className="w-[140px]" />
            </span>
          </div>
        </nav>

        <div className="space-x-[35px] sm:flex hidden">
          <button
            type="button"
            onClick={() => {
              const url = "https://ca.whatconn.co.kr/file/terms_of_service.pdf";
              window.open(url, "_blank", "noopener, noreferrer");
            }}
          >
            이용약관
          </button>
          <button
            type="button"
            onClick={() => {
              navigate("/event");
            }}
          >
            이벤트
          </button>
          <button type="button">고객센터</button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
