import { ethers } from "ethers";
import CheckCurrentNetwork from "../network/CheckCurrentNetwork";
import MatchChainIdAndRouterAdd from "../network/MatchChainIdAndRouterAdd";
import MatchChainIdAndAuction from "../network/MatchChainIdAndAuction";
import SendBuyNFTInfoToServer from "./SendBuyNFTInfoToServer";
import NotiToastify from "../../UI/common/NotiToastify";
import WarningToastify from "../../UI/common/WarningToastify";
import MatchChainIdAndNetworkName from "../network/MatchChainIdAndNetworkName";

const BuyNFT = async (
  auctionNum: number,
  price: number,
  nftId: number,
  saleId: number
) => {
  try {
    // Provider 연결
    let provider = new ethers.BrowserProvider(window.ethereum);
    let signer = await provider.getSigner();
    const currentNetwork = await CheckCurrentNetwork();
    const networkName: any = MatchChainIdAndNetworkName(currentNetwork);

    // Router Contract 가져오기
    const router: any = await MatchChainIdAndRouterAdd(currentNetwork);
    const routerContractAbi = require("../../../utils/contract/MarketplaceRouter.json");
    const routerContract = new ethers.Contract(
      router,
      routerContractAbi.abi,
      signer
    );

    const platform = MatchChainIdAndAuction(currentNetwork);

    // 구매할 NFT 정보
    const param = {
      Platform: platform,
      Index: auctionNum, // auction_index - 몇 번째 auction으로 올라와있는 nft를 살 건지? (ex. 6번)
      Price: String(price * 10 ** 18), // 가격을 price로 설정 (단위를 wei로 변환)
    };

    const stringNum = String(price);

    // 구매 실행 - 마지막 인수인 {value : ethers.parseEther("0.002")}는
    // 사용자가 지불하는 금액이므로, param.Price와 같은 값이어야 합니다)
    // *ethers.parseEther("0.002") = 0.002 ethers 입니다*
    const buy = await routerContract.bid(
      param.Platform,
      param.Index,
      param.Price,
      { value: ethers.parseEther(stringNum) }
    );

    const result = await buy.wait();
    const data = {
      saleId: saleId,
      nftId: nftId,
      buyerWalletAddress: result.logs[1].args[0],
      buyTransactionHash: result.logs[1]["transactionHash"],
    };
    const serverResult = await SendBuyNFTInfoToServer(data, networkName);

    return true;
  } catch (error: any) {
    if (error instanceof Error) {
      const errorMessage = error.message;
      if (errorMessage.includes("Auction already ended.")) {
        WarningToastify("이미 판매된 아이템입니다.");
        return false;
      }
      if (errorMessage.includes("insufficient funds for")) {
        WarningToastify("지갑의 잔액을 확인해주세요.");
        // return false;
      } else {
        return false;
      }
    }
    return false;
  }
};

export default BuyNFT;
