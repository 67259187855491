import DaumPostcode from "react-daum-postcode";
import SearchCoords from "../../../../Feat/map/SearchCoords";
import { useEffect, useState } from "react";

interface SearchAddressModalContentProps {
  setAddress: (address: string) => void;
  setLongitude: (longitude: number) => void;
  setLatitude: (latitude: number) => void;
  setIsActiveSearchAddressPopUp: (isActiveSearchAddressPopUp: boolean) => void;
  isActiveSearchAddressPopUp: boolean; // isActiveSearchAddressPopUp의 타입을 명시
}

const SearchAddressModalContent: React.FC<SearchAddressModalContentProps> = ({
  setAddress,
  setLongitude,
  setLatitude,
  setIsActiveSearchAddressPopUp,
  isActiveSearchAddressPopUp,
}) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const onCompletePost = async (data: any) => {
    setAddress(data.address);
    const coords: any = await SearchCoords(data.address);
    if (coords.getLng()) {
      const x = coords.getLng();
      const y = coords.getLat();
      setLongitude(x);
      setLatitude(y);
    }
    setIsActiveSearchAddressPopUp(false);
  };

  useEffect(() => {
    // Daum 우편번호 스크립트를 동적으로 불러오는 함수
    const loadScript = () => {
      const script = document.createElement("script");
      script.src =
        "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
      script.async = true;
      script.onload = () => {
        setScriptLoaded(true);
      };
      document.body.appendChild(script);
    };

    // 스크립트를 불러오기
    loadScript();
  }, []);

  // isActiveSearchAddressPopUp 값이 false일 때 컴포넌트를 렌더링하지 않음
  if (!isActiveSearchAddressPopUp) {
    return null;
  }

  return (
    <div key={new Date().getTime()}>
      {scriptLoaded ? (
        <DaumPostcode onComplete={onCompletePost}></DaumPostcode>
      ) : (
        <p>Loading ...</p>
      )}
    </div>
  );
};

export default SearchAddressModalContent;
