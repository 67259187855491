import React from "react";
import CheckCurrentNetwork from "../network/CheckCurrentNetwork";
import MatchChainIdAndNetworkName from "../network/MatchChainIdAndNetworkName";
import CheckValidationServiceChain from "../wallet/CheckValidationServiceChain";
import { ConnectMetaMask } from "../wallet/ConnectMetaMask";
import NotiToastify from "../../UI/common/NotiToastify";
import UploadDataToNftStorage from "./UploadDataToNftStorage";
import MintNFT from "./MintNFT";
import instance from "../axios/instance";
import ValidateLoginWalletList from "../wallet/ValidateLoginWalletList";
import WarningToastify from "../../UI/common/WarningToastify";

const MintingEtcNFT = async (
  mintingData: any,
  petId: any,
  setIsActiveModal: any,
  nftType: any
) => {
  const currentNetwork = await CheckCurrentNetwork();
  const validateServiceChain = CheckValidationServiceChain(currentNetwork);
  const networkName = MatchChainIdAndNetworkName(currentNetwork);
  const currentUserWallet = await ConnectMetaMask();

  const validateWalletStatus = await ValidateLoginWalletList(currentUserWallet);

  if (!validateWalletStatus) {
    WarningToastify("등록된 지갑주소로 연결해주세요.");
    return;
  }

  if (!validateServiceChain) {
    setIsActiveModal(false);
    NotiToastify(
      <div>
        서비스 제공중인 네트워크로 연결해주세요
        <br />
        &#40;서비스 체인 : SEPOLIA,BNB,BAOBAB,CRONOS &#41;
      </div>
    );

    return;
  } else {
    setIsActiveModal(true);

    const responseNft: any = await UploadDataToNftStorage(mintingData);
    if (responseNft.status) {
      const metaData = responseNft.data;
      const ipfsURI: any = responseNft.ifpsURL;
      if (
        metaData !== null &&
        metaData !== undefined &&
        ipfsURI !== null &&
        ipfsURI !== undefined
      ) {
        const result: any = await MintNFT(currentNetwork, ipfsURI);
        if (result.status == true && result.data.tokenID) {
          const sendToServerData = {
            walletAddress: currentUserWallet,
            petId: petId,
            name: metaData.name,
            image: metaData.image,
            nftType: nftType,
            metaData: metaData,
            tokenId: result?.data.tokenID,
            network: networkName,
            mintingTransactionHash: result?.data.transactionHash,
          };

          try {
            const response = await instance.post(
              process.env.REACT_APP_SERVER_API + "/nfts/minting",
              sendToServerData,
              {
                headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                },
              }
            );

            if (response.status == 200) {
              setIsActiveModal(false);
              NotiToastify("민팅에 성공했습니다.");
              window.location.href = "/agency/mypage";
            } else {
              setIsActiveModal(false);
            }
          } catch (error: any) {
            setIsActiveModal(false);
          }
        } else {
          setIsActiveModal(false);
          NotiToastify("블록체인 민팅에 실패했습니다. 다시 시도해주세요.");
          return;
        }
      }
    } else {
      setIsActiveModal(false);
    }
  }
};

export default MintingEtcNFT;
