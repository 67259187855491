import axios from "axios";
import { useEffect, useState } from "react";
import instance from "../../../components/Feat/axios/instance";
import RemoveLoginStatusInSession from "../../../components/Feat/auth/RemoveLoginStatusInSession";
import { ConnectMetaMask } from "../../../components/Feat/wallet/ConnectMetaMask";
import ConnectKaikas from "../../../components/Feat/wallet/klaytn/ConnectKaikas";
import metamask_logo from "../../../common/images/metamask2x.png";
import kaikas_logo from "../../../common/images/kaikas_logo.png";
import UpdateWalletAddressList from "../../../components/Feat/wallet/UpdateWalletAddressList";

const RedirectLoginPage = () => {
  const [loginStatus, setLoginStatus] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [accessTokenState, setAccessTokenState] = useState("");

  const requestSocialLogin = async (code: any) => {
    const redirectURL =
      window.location.protocol + "//" + window.location.host + "/login/social";
    const url =
      "https://api.six-steps.com/auth/users/login/kakao?code=" +
      code +
      "&redirectUri=" +
      redirectURL;

    try {
      const response = await axios.get(url);
      if (response.data.status === 200) {
        setLoginStatus(true);
        const accessToken = response.data.data.accessToken;
        const role = response.data.data.role;

        const user = {
          loginAddress: "",
          role: role,
          accessToken: accessToken,
          walletType: "",
          type: response.data.data.type,
        };
        sessionStorage.setItem("login_user", JSON.stringify(user));
        const url = process.env.REACT_APP_SERVER_API + "/users/profile";
        const userInfoRes = await instance.get(url);
        if (userInfoRes.status == 200) {
          setWalletList(userInfoRes.data.data.walletList);
          setAccessTokenState(accessToken);
        }
        await UpdateWalletAddressList(role);
      }
    } catch (error: any) {
      if (error.response.status == 404) {
        alert("회원 가입되지 않은 계정입니다.");
        window.location.href = "/";
        return;
      } else if (error.response.status == 400) {
        alert("잘못된 요청입니다. ");
        RemoveLoginStatusInSession();
        window.location.href = "/";
      } else {
        alert("로그인에 실패했습니다.");
      }
      return false;
    }
  };

  const metamaskLogin = async () => {
    const currentWalletAddress: any = await ConnectMetaMask();
    if (currentWalletAddress) {
      const isWalletInList = walletList.some(
        (item: any) => item.walletAddress === currentWalletAddress
      );
      if (isWalletInList) {
        const user = {
          loginAddress: currentWalletAddress,
          role: "ROLE_USER",
          accessToken: accessTokenState,
          walletType: "METAMASK",
          type: "USER",
        };
        sessionStorage.setItem("login_user", JSON.stringify(user));
        window.location.href = "/";
      } else {
        alert("회원 가입한 지갑주소로 연결해주세요.");
        return;
      }
    }
  };

  const kaikasLogin = async () => {
    const currentWalletAddress: any = await ConnectKaikas();
    if (currentWalletAddress) {
      const isWalletInList = walletList.some(
        (item: any) => item.walletAddress === currentWalletAddress
      );
      if (isWalletInList) {
        const user = {
          loginAddress: currentWalletAddress,
          role: "ROLE_USER",
          accessToken: accessTokenState,
          walletType: "KAIKAS",
          type: "USER",
        };
        sessionStorage.setItem("login_user", JSON.stringify(user));
        window.location.href = "/";
      } else {
        alert("회원 가입한 지갑주소로 연결해주세요.");
        return;
      }
    }
  };

  useEffect(() => {
    const code = new URL(window.location.href).searchParams.get("code");
    requestSocialLogin(code);
  }, []);

  return (
    <div className="flex items-center justify-center w-full min-h-screen">
      {loginStatus && (
        <div className="w-[80%] flex flex-col items-center justify-center ">
          <div className="text-[18px]">
            현재 계정으로 등록된 지갑 리스트입니다.
          </div>
          <div className="flex flex-col">
            {walletList.map((item: any, index) => {
              return (
                <div
                  key={index}
                  className="border border-black p-3 flex flex-col m-2 "
                >
                  <div>지갑 주소 : {item.walletAddress}</div>
                  <div>지갑 종류 : {item.walletType}</div>
                </div>
              );
            })}
          </div>
          <div className="flex space-x-3 mt-5">
            <button
              type="button"
              onClick={() => {
                metamaskLogin();
              }}
              className="px-2 cursor-pointer w-[230px] flex items-center justify-center shadow-lg rounded-[14px] shadow-gray-300 py-3 text-white bg-[#233447]"
            >
              <img src={metamask_logo} className="w-[24px] h-[24px]" />
              <span className="ml-4">메타마스크 로그인</span>
            </button>
            <button
              className="px-2 cursor-pointer w-[230px] flex items-center justify-center shadow-lg rounded-[14px] shadow-gray-300 py-3 text-white bg-[#3366FF]"
              type="button"
              onClick={() => {
                kaikasLogin();
              }}
            >
              <img src={kaikas_logo} className="w-[22px] h-[22px]" />
              <span className="ml-4"> 카이카스 로그인</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RedirectLoginPage;
