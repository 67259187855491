import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

const MainLayout: React.FC<ParentLayoutProps> = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex items-center justify-center flex-col">
      <Header />
      <div className="min-h-screen max-w-[1360px] w-full">{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
