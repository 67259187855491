import React from "react";
import EmergencyIntroSlide from "./EmergencyIntroSlide";

const EmergencyIntroContainer = () => {
  return (
    <div className="flex flex-col mt-[120px] items-center w-full">
      <div className="w-[80%]">
        <h3 className="text-[36px] font-bold">긴급구호 유기동물 NFT</h3>
        <h5 className="text-[22px]  mt-4 ">
          긴급한 손길이 필요한 유기동물을 도와주세요
        </h5>
      </div>

      <EmergencyIntroSlide />
    </div>
  );
};

export default EmergencyIntroContainer;
