import React, { useEffect, useRef, useState } from "react";
import UploadDataToNftStorage from "../../../Feat/nft/UploadDataToNftStorage";
import MintNFT from "../../../Feat/nft/MintNFT";
import UploadSVG from "../../../../common/svg/UploadSVG";
import CheckCurrentNetwork from "../../../Feat/network/CheckCurrentNetwork";
import MatchChainIdAndNetworkName from "../../../Feat/network/MatchChainIdAndNetworkName";
import { useNavigate } from "react-router-dom";
import CheckValidationServiceChain from "../../../Feat/wallet/CheckValidationServiceChain";
import Modal from "../../common/Modal";
import IsMitingModalContent from "./IsMitingModalContent";
import WarningToastify from "../../common/WarningToastify";
import NotiToastify from "../../common/NotiToastify";
import instance from "../../../Feat/axios/instance";
import KlaytnMinting from "../../../Feat/nft/klaytn/KlaytnMinting";
import ValidateLoginWalletList from "../../../Feat/wallet/ValidateLoginWalletList";
import { ConnectMetaMask } from "../../../Feat/wallet/ConnectMetaMask";
import ConnectKaikas from "../../../Feat/wallet/klaytn/ConnectKaikas";
import CheckCurrentKlaytnNetwork from "../../../Feat/wallet/klaytn/CheckCurrentKlaytnNetwork";

const RegistRegisterNFT = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isAgreed, setIsAgreed] = useState(false);
  const [name, setName] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [description, setDescription] = useState("");
  const [breed, setBreed] = useState("");
  const [gender, setGender] = useState("FEMALE");
  const [age, setAge] = useState<number>(0); // number 타입으로 설정
  const [ageVerified, setAgeVerified] = useState(false);
  const [rescuedYear, setRescuedYear] = useState(0); // 년도 입력 상태
  const [rescuedMonth, setRescuedMonth] = useState(0); // 월 입력 상태
  const [rescuedDay, setRescuedDay] = useState(0); // 일 입력 상태
  const [rescuedLocation, setRescuedLocation] = useState("");
  const [tokenId, setTokenId] = useState(1);
  const navigate = useNavigate();
  const [noticeNetwork, setNoticeNetwork] = useState("");
  const [isActiveMinting, setIsActiveModal] = useState(false);
  const [currentUserAddress, setCurrentUserAddress] = useState("");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile: File | null = e.target.files?.[0] || null;
    setFile(selectedFile);
  };

  const validationCheck = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();

    if (name.trim() == "") {
      alert("이름을 입력해주세요.");
      document.getElementById("name")?.focus();
      return;
    } else if (description.trim() == "") {
      alert("설명을 입력해주세요.");
      document.getElementById("description")?.focus();
      return;
    } else if (breed.trim() == "") {
      alert("품종을 입력해주세요.");
      document.getElementById("breed")?.focus();
      return;
    } else if (age == 0) {
      alert("나이를 올바르게 입력해주세요.");
      document.getElementById("age")?.focus();
      return;
    } else if (rescuedLocation.trim() == "") {
      alert("구조장소를 입력해주세요.");
      document.getElementById("rescuedLocation")?.focus();
      return;
    } else if (
      rescuedYear === 0 ||
      null ||
      rescuedMonth === 0 ||
      null ||
      rescuedDay === 0 ||
      null
    ) {
      alert("구조일자를 올바르게 입력해주세요.");
      return;
    } else if (rescuedYear == currentYear) {
      if (rescuedMonth > currentMonth) {
        alert("구조날짜는 현재 날짜보다 과거여야 합니다. ");
        return;
      } else if (rescuedMonth == currentMonth) {
        if (rescuedDay > currentDay) {
          alert("구조일자는 현재 날짜보다 과거여야 합니다.");
          return;
        }
      }
    }
    if (file === null) {
      NotiToastify("이미지 업로드 후 다시 시도해주세요.");
      return;
    } else if (!isAgreed) {
      NotiToastify("수정 불가능 동의란에 체크해주세요");
      return;
    }
    return true;
  };

  const handleUpload = async () => {
    const userStatusString: any = sessionStorage.getItem("login_user");
    const userStatus = JSON.parse(userStatusString);
    if (userStatus.walletType == "METAMASK") {
      const validation = validationCheck();
      if (!validation) {
        return false;
      }
      const currentWalletAddress = await ConnectMetaMask();
      const checkValidateWallet = await ValidateLoginWalletList(
        currentWalletAddress
      );

      if (checkValidateWallet) {
        setIsActiveModal(true);
        const currentNetwork = await CheckCurrentNetwork();
        const networkName: any = MatchChainIdAndNetworkName(currentNetwork);
        const validateServiceChain =
          CheckValidationServiceChain(currentNetwork);

        if (!validateServiceChain) {
          setIsActiveModal(false);
          NotiToastify(
            <div>
              서비스 제공중인 네트워크로 연결해주세요
              <br />
              &#40;서비스 체인 : SEPOLIA,BNB,BAOBAB,CRONOS &#41;
            </div>
          );
          return;
        } else {
          try {
            const validateDate =
              rescuedYear + "-" + rescuedMonth + "-" + rescuedDay;
            const mintingData = {
              name: name,
              image: file,
              description: description,
              breed: breed,
              gender: gender,
              age: age,
              ageVerified: ageVerified,
              rescuedDate: validateDate,
              rescuedLocation: rescuedLocation,
              tokenId: tokenId,
              network: networkName,
              walletAddress: currentUserAddress,
              mintingTransactionHash: "",
            };

            const responseNft: any = await UploadDataToNftStorage(mintingData);
            if (responseNft.status) {
              const data = responseNft.data;
              const ipfsURI: any = responseNft.ifpsURL;
              if (
                data !== null &&
                data !== undefined &&
                ipfsURI !== null &&
                ipfsURI !== undefined
              ) {
                const result: any = await MintNFT(currentNetwork, ipfsURI);
                if (result.status == true && result.data.tokenID) {
                  data.tokenId = result?.data.tokenID;
                  data.mintingTransactionHash = result?.data.transactionHash;
                  if (data.tokenId !== "" && data.tokenId !== undefined) {
                    try {
                      const response = await instance.post(
                        process.env.REACT_APP_SERVER_API + "/pets/minting",
                        data,
                        {
                          headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                          },
                        }
                      );

                      if (response.status == 200) {
                        setIsActiveModal(false);
                        NotiToastify("민팅에 성공했습니다.");
                        navigate("/agency/mypage");
                      } else {
                        setIsActiveModal(false);
                      }
                    } catch (error: any) {
                      // console.log("서버에 민팅 데이터 업로드 실패", error);
                    }
                  } else {
                    setIsActiveModal(false);
                  }
                } else {
                  setIsActiveModal(false);
                  NotiToastify(
                    "블록체인 민팅에 실패했습니다. 다시 시도해주세요."
                  );
                  return;
                }
              }
            } else if (!responseNft.status) {
              setIsActiveModal(false);
              WarningToastify("ipfs 업로드에 실패했습니다.");
              return;
            }
          } catch (error) {
            console.error("에러 발생 :", error);
            return;
          }
        }
      } else {
        WarningToastify("회원가입한 지갑주소로 접속해주세요.");
        return;
      }
    }

    if (userStatus.walletType == "KAIKAS") {
      const validation = validationCheck();
      if (!validation) {
        return false;
      }

      const currentWalletAddress = await ConnectKaikas();
      const checkValidateWallet = await ValidateLoginWalletList(
        currentWalletAddress
      );

      const { chainId }: any = await CheckCurrentKlaytnNetwork();
      const networkName: any = MatchChainIdAndNetworkName(chainId);

      if (checkValidateWallet) {
        try {
          setIsActiveModal(true);
          const validateDate =
            rescuedYear + "-" + rescuedMonth + "-" + rescuedDay;
          const mintingData = {
            name: name,
            image: file,
            description: description,
            breed: breed,
            gender: gender,
            age: age,
            ageVerified: ageVerified,
            rescuedDate: validateDate,
            rescuedLocation: rescuedLocation,
            tokenId: tokenId,
            network: networkName,
            walletAddress: currentUserAddress,
            mintingTransactionHash: "",
          };

          const responseNft: any = await UploadDataToNftStorage(mintingData);
          if (responseNft.status) {
            const data = responseNft.data;
            const ipfsURI: any = responseNft.ifpsURL;
            if (
              data !== null &&
              data !== undefined &&
              ipfsURI !== null &&
              ipfsURI !== undefined
            ) {
              const result: any = await KlaytnMinting(ipfsURI, networkName);
              if (result.success == true && result.data.NFTId) {
                data.tokenId = result?.data.NFTId;
                data.mintingTransactionHash = result?.transactionHash;

                if (data.tokenId !== "" && data.tokenId !== undefined) {
                  try {
                    console.log("서버에 날린 data", data);
                    const response = await instance.post(
                      process.env.REACT_APP_SERVER_API + "/pets/minting",
                      data,
                      {
                        headers: {
                          "Content-Type": "application/json;charset=UTF-8",
                        },
                      }
                    );

                    if (response.status == 200) {
                      setIsActiveModal(false);
                      NotiToastify("민팅에 성공했습니다.");
                      navigate("/agency/mypage");
                    } else {
                      setIsActiveModal(false);
                    }
                  } catch (error: any) {
                    console.log("서버에 민팅 데이터 업로드 실패", error);
                  }
                } else {
                  setIsActiveModal(false);
                }
              } else {
                setIsActiveModal(false);
                NotiToastify(
                  "블록체인 민팅에 실패했습니다. 다시 시도해주세요."
                );
                return;
              }
            }
          } else if (!responseNft.status) {
            setIsActiveModal(false);
            WarningToastify("ipfs 업로드에 실패했습니다.");
            return;
          }
        } catch (error) {
          console.error("에러 발생 :", error);
          return;
        }
      } else {
        WarningToastify("회원가입한 지갑주소로 접속해주세요.");
        return;
      }
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("login_user") !== null) {
      const userStatusString: any = sessionStorage.getItem("login_user");
      const userStatus = JSON.parse(userStatusString);

      setCurrentUserAddress(userStatus.loginAddress);
      if (userStatus.type == "RIGHTS_GROUP") {
        if (userStatus.walletType == "METAMASK") {
          const currentNetwork = CheckCurrentNetwork();
          currentNetwork.then((network) => {
            const result = CheckValidationServiceChain(network);
            if (!result) {
              setNoticeNetwork(
                "* 서비스 제공중인 네트워크로 연결해주세요 (서비스 체인 : SEPOLIA,BNB,BAOBAB,CRONOS)"
              );
              return;
            } else {
              setNoticeNetwork(
                "* 현재 네트워크는 이용 가능한 네트워크 체인입니다. "
              );
              return;
            }
          });
        }

        return;
      } else if (
        userStatus.type == "FEED_COMPANY" ||
        userStatus.type == "USER"
      ) {
        WarningToastify("기관 계정으로 로그인해주세요.");
        navigate("/");
      }
    } else {
      NotiToastify("로그인을 먼저 진행해주세요");
      navigate("/");
      return;
    }
  }, []);

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <Modal
        content={<IsMitingModalContent />}
        isActiveModal={isActiveMinting}
        closeModal={setIsActiveModal}
      />
      <div className="flex flex-col items-center justify-center mt-[40px] mb-[120px]">
        <div className="w-full">
          <div className="nfttype-type-button">등록 NFT</div>
          <h3 className="text-[42px] text-brand100 font-semibold w-full mb-[10px] mt-[5px]">
            동물 NFT 생성
          </h3>
          <div className="text-brand100 mb-[20px]"> {noticeNetwork}</div>
        </div>

        <div className="flex mt-[40px] w-full">
          <div className="space-y-10">
            <div className="flex">
              <span className="register-form-name">이름</span>
              <input
                id="name"
                type="text"
                maxLength={10}
                className=" border-gray40 h-[36px] w-fit text-center rounded-[30px]"
                placeholder="이름입력"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            {/* 설명은 300자 제한 */}
            <div className="flex">
              <span className="register-form-name">설명</span>
              <textarea
                id="description"
                maxLength={300}
                className=" border-gray40 text-start  h-[150px] w-[460px] rounded-[30px]  p-5 "
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>

            <div className="flex">
              <span className="register-form-name">품종</span>
              <input
                id="breed"
                type="text"
                maxLength={30}
                className=" border-gray40 h-[36px] w-fit text-center rounded-[30px]"
                placeholder="품종을 입력해 주세요"
                onChange={(e) => {
                  setBreed(e.target.value);
                }}
              />
            </div>
            <div className="flex">
              <span className="register-form-name">나이</span>
              <input
                type="number"
                onKeyDown={(e) => e.preventDefault()}
                className=" border-gray40 h-[36px] w-[76px] text-center rounded-[30px]"
                min={0}
                max={100}
                onChange={(e: any) => {
                  const stringNum = e.target.value;
                  const numberNum = Number(stringNum);
                  setAge(numberNum);
                }}
              />
              <span className="pl-[6px] text-gray80">세</span>
              <button
                type="button"
                className={`ml-7 rounded-[30px] ${
                  ageVerified
                    ? "bg-[#F5D9C6] text-brand100"
                    : "border border-gray40"
                } w-[120px] h-[36px] text-gray80`}
                onClick={() => {
                  setAgeVerified(true);
                }}
              >
                확실해요
              </button>
              <button
                type="button"
                className={`ml-3 rounded-[30px] ${
                  ageVerified
                    ? "border border-gray40"
                    : "bg-[#F5D9C6] text-brand100"
                } w-[120px] h-[36px] text-gray80`}
                onClick={() => {
                  setAgeVerified(false);
                }}
              >
                불확실해요
              </button>
            </div>
            <div className="flex">
              <div className="register-form-name flex flex-col font-semibold">
                <span> 성별 및</span>
                <span>중성화 여부</span>
              </div>
              <div className="flex flex-col">
                <h3 className="text-gray80">해당되는 항목을 선택해 주세요</h3>
                <div className="flex flex-row mt-4">
                  <button
                    type="button"
                    className={`rounded-[30px] ${
                      gender === "FEMALE"
                        ? "bg-[#F5D9C6]"
                        : "border border-[#f5d9c6]"
                    } w-[120px] h-[36px] text-brand100`}
                    onClick={() => {
                      setGender("FEMALE");
                    }}
                  >
                    암컷
                  </button>
                  <button
                    type="button"
                    className={`ml-3 rounded-[30px] ${
                      gender === "MALE"
                        ? "bg-[#F5D9C6]"
                        : "border border-[#f5d9c6]"
                    } w-[120px] h-[36px] text-brand100`}
                    onClick={() => {
                      setGender("MALE");
                    }}
                  >
                    수컷
                  </button>
                  <button
                    type="button"
                    className={`ml-3 rounded-[30px] ${
                      gender === "NEUTRALIZED"
                        ? "bg-[#F5D9C6]"
                        : "border border-[#f5d9c6]"
                    } w-[120px] h-[36px] text-brand100`}
                    onClick={() => {
                      setGender("NEUTRALIZED");
                    }}
                  >
                    중성화
                  </button>
                </div>
              </div>
            </div>
            <div className="flex">
              <span className="register-form-name">구조장소</span>
              <input
                type="text"
                maxLength={30}
                className=" px-5 border-gray40 h-[36px] w-fit text-center rounded-[30px]"
                placeholder="구조장소를 입력해 주세요"
                id="rescuedLocation"
                onChange={(e) => {
                  setRescuedLocation(e.target.value);
                }}
              />
            </div>
            <div className="flex">
              <span className="register-form-name">구조일자</span>
              <input
                type="number"
                min={2010}
                max={new Date().getFullYear()}
                onKeyDown={(e) => e.preventDefault()}
                className=" border-brand100 text-brand100 h-[36px] w-[100px] text-center  rounded-[30px]"
                onChange={(e: any) => {
                  setRescuedYear(e.target.value);
                }}
              />
              <span className="text-brand100 pl-2">년</span>
              <input
                min={1}
                max={12}
                type="number"
                onKeyDown={(e) => e.preventDefault()}
                className="ml-7 border-brand100 text-brand100 h-[36px] w-[76px] text-center  rounded-[30px]"
                onChange={(e: any) => {
                  setRescuedMonth(e.target.value);
                }}
              />
              <span className="text-brand100 ml-2">월</span>
              <input
                type="number"
                min={1}
                max={31}
                onKeyDown={(e) => e.preventDefault()}
                className="ml-5 border-brand100  text-brand100 h-[36px] w-[76px] text-center  rounded-[30px]"
                onChange={(e: any) => {
                  setRescuedDay(e.target.value);
                }}
              />
              <span className="text-brand100 ml-2">일</span>
            </div>
          </div>
          <div>
            <div className="sm:ml-[140px] relative  w-[400px]">
              <h3 className="font-bold  text-gray100 mb-[16px]">
                파일 업로드 및 미리보기
              </h3>
              {file ? (
                <img
                  src={URL.createObjectURL(file)}
                  className="sm:w-[420px] sm:h-[420px] relative object-cover "
                  alt="선택한 이미지"
                />
              ) : (
                <div className="sm:w-[420px] sm:h-[420px] bg-[#F7F7F7] flex items-center justify-center">
                  이미지를 선택하세요
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="absolute right-[14px] top-[58px] rounded-[30px] h-[34px] flex items-center justify-center bg-brand100 text-white w-[126px] text-center cursor-pointer"
              >
                <span className="mr-2"> 파일 업로드</span>
                <UploadSVG fillColor="#ffffff" height={16} width={16} />
              </button>

              <div className="flex flex-row items-center w-full mt-[140px]">
                <input
                  type="checkbox"
                  className="rounded-full border border-brand100 checked:bg-brand100"
                  checked={isAgreed}
                  onChange={(e) => {
                    setIsAgreed(e.target.checked);
                  }}
                />
                <h3 className="text-brand100 ml-2 font-semibold">
                  생성된 NFT에 대해 수정이 불가능합니다. 동의하십니까?
                </h3>
              </div>

              <div className="mt-[60px]  w-full flex items-center justify-center">
                <button
                  type="button"
                  className="bg-brand100 text-white w-[140px] h-[46px] rounded-[30px] text-[22px]"
                  onClick={handleUpload}
                >
                  민팅하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistRegisterNFT;
