import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WarningToastify from "../common/WarningToastify";
import NotiToastify from "../common/NotiToastify";

const NftUploadContainer = () => {
  const navigation = useNavigate();
  let agencyType: any;
  useEffect(() => {
    if (sessionStorage.getItem("login_user") !== null) {
      const userStatusString: any = sessionStorage.getItem("login_user");
      const userStatus = JSON.parse(userStatusString);
      const role = userStatus.role;
      agencyType = userStatus.type;
      if (role == "ROLE_AGENCY") {
        return;
      } else if (role == "ROLE_USER") {
        WarningToastify("기관 계정으로 로그인해주세요.");
        navigation("/");
      }
    } else {
      NotiToastify("로그인을 먼저 진행해주세요");

      return;
    }
  }, []);
  return (
    <div className="flex flex-col items-center justify-center">
      <h3 className="text-brand100 mt-[60px] text-[34px] font-semibold">
        NFT 생성하기
      </h3>
      <div className="flex flex-col items-center justify-center">
        <h5 className="text-[#470B11] mt-[55px] text-[18px]">
          아직 NFT를 등록한 동물이 없나요?
        </h5>
        <button
          type="button"
          className="bg-[#f5d9c6] mt-[20px] text-white w-[120px] h-[42px] rounded-[20px] text-[18px] hover:bg-brand100"
          onClick={() => {
            if (agencyType == "RIGHTS_GROUP") {
              navigation("/nftUpload/register");
            } else if (agencyType == "FEED_COMPANY") {
              NotiToastify("사료 업체는 등록 불가능합니다.");
              return;
            }
          }}
        >
          등록
        </button>
      </div>
      <div className="flex flex-col items-center justify-center mt-[40px]">
        <h5 className="text-[#470B11] text-[18px]">
          등록된 동물의 다른 NFT를 등록하고 싶다면
        </h5>
        <div className="flex flex-col space-y-4 mt-[20px] ">
          <button
            type="button"
            className="bg-[#F5D9C6] text-white w-[120px]  h-[42px] rounded-[20px] text-[18px] hover:bg-brand100"
            onClick={() => {
              if (agencyType == "RIGHTS_GROUP") {
                navigation("/nftUpload/life");
              } else if (agencyType == "FEED_COMPANY") {
                NotiToastify("사료 업체는 등록 불가능합니다.");
                return;
              }
            }}
          >
            생활
          </button>
          <button
            type="button"
            className="bg-[#F5D9C6] text-white w-[120px] h-[42px] rounded-[20px] text-[18px] hover:bg-brand100"
            onClick={() => {
              if (agencyType == "RIGHTS_GROUP") {
                navigation("/nftUpload/clinic");
              } else if (agencyType == "FEED_COMPANY") {
                NotiToastify("사료 업체는 등록 불가능합니다.");
                return;
              }
            }}
          >
            진료
          </button>
          <button
            type="button"
            className="bg-[#F5D9C6] text-white w-[120px] h-[42px] rounded-[20px] text-[18px] hover:bg-brand100"
            onClick={() => {
              if (agencyType == "RIGHTS_GROUP") {
                NotiToastify("동물보호단체는 등록 불가능합니다.");
                return;
              } else if (agencyType == "FEED_COMPANY") {
                navigation("/nftUpload/donation");
              }
            }}
          >
            기부
          </button>
          <button
            type="button"
            className="bg-[#F5D9C6] text-white w-[120px] h-[42px] rounded-[20px] text-[18px] hover:bg-brand100"
            onClick={() => {
              if (agencyType == "RIGHTS_GROUP") {
                navigation("/nftUpload/adopt");
              } else if (agencyType == "FEED_COMPANY") {
                NotiToastify("사료 업체는 등록 불가능합니다.");
                return;
              }
            }}
          >
            입양
          </button>
        </div>
      </div>
    </div>
  );
};

export default NftUploadContainer;
