import { useNavigate, useLocation } from "react-router-dom";
import LoginModalContent from "../auth/LoginModalContent";
// import React, { useEffect, useState } from "react";
import sixsteps_main_logo from "../../../common/images/6_steps.jpeg";
import RemoveLoginStatusInSession from "../../Feat/auth/RemoveLoginStatusInSession";
import { useRecoilState } from "recoil";
import { searchKeywordState } from "../../../utils/recoil/searchKeyword/atom";
import CheckCurrentNetwork from "../../Feat/network/CheckCurrentNetwork";
import CheckValidationServiceChain from "../../Feat/wallet/CheckValidationServiceChain";
import NotiToastify from "../common/NotiToastify";

import LoginModal from "../auth/LoginModal";
import { useEffect, useState } from "react"; // React와 lazy, Suspense 추가

const Header = () => {
  const navigate = useNavigate();
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [searchKeyword, setSearchKeyword] = useRecoilState(searchKeywordState);
  // 현재 페이지 URL 가져오기
  const location = useLocation();
  const currentPath = location.pathname;
  const [isAgency, setIsAgency] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("login_user") !== null) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
      return;
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("login_user") !== null) {
      const userStatusString: any = sessionStorage.getItem("login_user");
      const userStatus = JSON.parse(userStatusString);
      const role = userStatus.role;
      if (role == "ROLE_AGENCY") {
        setIsAgency(true);
        return;
      } else if (role == "ROLE_USER") {
        setIsAgency(false);
      }
    } else {
      // alert("로그인을 먼저 진행해주세요");
      return;
    }
  }, []);

  return (
    <header className="text-gray-600 body-font w-full px-[120px] sm:px-[80px]">
      {/* 로그인 모달 */}
      <LoginModal
        content={<LoginModalContent />}
        isActiveModal={isActiveModal}
        closeModal={setIsActiveModal}
      />
      <div className=" flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a
          className="flex title-font font-medium items-center cursor-pointer text-gray-900 mb-4 md:mb-0"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={sixsteps_main_logo} className="w-[68px] h-[54px]" />
          {/* <span className="ml-3 text-2xl font-black ">ANIMALCON</span> */}
        </a>
        {currentPath === "/nftList" ? ( // "/nftList" 페이지에서만 검색 입력 필드 표시
          <nav className="md:ml-[50px] sm:max-w-[480px]  max-w-[360px] w-full md:mr-auto flex flex-wrap items-center text-base justify-center"></nav>
        ) : (
          <nav className="md:ml-[50px] sm:max-w-[480px]  max-w-[360px] w-full md:mr-auto flex flex-wrap items-center text-base justify-center">
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center ">
                <svg
                  className="w-[15.9px] h-[15.9px] text-[#E06514] font-semibold ml-[17px] cursor-pointer hover:text-orange-400 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                  onClick={() => {
                    navigate("/nftList");
                  }}
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                className="w-full placeholder:text-[#E06713] h-[42px] p-4 pl-11 text-[18px] text-[#E06514] border border-[#E06514] rounded-[30px]"
                placeholder="검색"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    navigate("/nftList");
                  }
                }}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
              />
            </div>
          </nav>
        )}

        {isLogin ? (
          <div className="space-x-[35px] mt-[30px] sm:mt-0 text-[#E06713] ">
            <button
              onClick={() => {
                window.location.href = "/nftList";
              }}
              className=" inline-flex items-center  rounded text-[20px] "
            >
              NFT 검색
            </button>
            {isAgency && (
              <button
                className=" inline-flex items-center  rounded text-[20px] "
                onClick={() => {
                  navigate("/nftUpload");
                }}
              >
                NFT 생성
              </button>
            )}

            <button
              className=" inline-flex items-center  rounded text-[20px] "
              onClick={() => {
                if (isAgency) {
                  navigate("/agency/mypage");
                } else {
                  navigate("/user/mypage");
                }
              }}
            >
              마이페이지
            </button>
            <button
              className=" inline-flex items-center  rounded text-[20px] "
              onClick={() => {
                const result = RemoveLoginStatusInSession();
                if (result) {
                  window.location.href = "/";
                }
              }}
            >
              로그아웃
            </button>
          </div>
        ) : (
          <div className="space-x-[35px] mt-[30px] sm:mt-0 text-[#E06713]">
            <button
              onClick={() => {
                setIsActiveModal(true);
              }}
              className=" inline-flex items-center  rounded text-[20px] "
            >
              로그인
            </button>
            <button
              className=" inline-flex items-center  rounded text-[20px] "
              onClick={() => {
                window.location.href = "/register";
              }}
            >
              회원가입
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
