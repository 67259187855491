import KlaytnSetProvider from "./KlaytnSetProvider";
const nft_abi = require("../../../../utils/contract/StandardNFT.json");

const KlaytnMinting = async (nftMetaData: any, networkName?: string) => {
  var global: any = global || window;
  global.Buffer = global.Buffer || require("buffer").Buffer;
  let nft_contract = process.env.REACT_APP_KLAYTN_NFT_CONTRACT;
  if (networkName == "WHATCON") {
    nft_contract = process.env.REACT_APP_WHATCON_NFT_CONTRACT;
  }

  try {
    const provider = await window.klaytn;
    const setP: any = await KlaytnSetProvider(provider);
    if (!setP.success) {
      return new Error(setP.Error);
    }
    const p = setP.provider;
    const account = (await p.getAccounts())[0];
    const contract = new p.Contract(nft_abi.abi, nft_contract);
    contract.options.gas = 5000000;
    const res = await contract.methods
      .safeMint(account, nftMetaData)
      .send({ from: account });

    if (res.status && res.transactionHash != "") {
      return {
        success: true,
        data: {
          to: res.events.Transfer.returnValues.to,
          NFTId: res.events.Transfer.returnValues.tokenId,
        },
        transactionHash: res.transactionHash,
      };
    } else {
      return new Error(res);
    }
  } catch (err: any) {
    return new Error(err);
  }
};

export default KlaytnMinting;
