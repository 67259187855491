import KlaytnSetProvider from "./KlaytnSetProvider";
const nft_abi = require("../../../../utils/contract/StandardNFT.json");

const KlaytnSetIsApprovalForAll = async (
  to: any,
  status: any,
  networkName: any
) => {
  let router_contract: any;
  let nft_contract: any;
  if (networkName == "BAOBAB") {
    router_contract = process.env.REACT_APP_KLAYTN_ROUTER_CONTRACT;
    nft_contract = process.env.REACT_APP_KLAYTN_NFT_CONTRACT;
  } else if (networkName == "WHATCON") {
    router_contract = process.env.REACT_APP_ROUTER_WHATCON_CONTRACT_ADDRESS;
    nft_contract = process.env.REACT_APP_WHATCON_NFT_CONTRACT;
  }

  try {
    const provider = await window.klaytn;
    const setP: any = await KlaytnSetProvider(provider);
    if (!setP.success) {
      return new Error(setP.Error);
    }
    const p = setP.provider;
    const account = (await p.getAccounts())[0];
    const contract = new p.Contract(nft_abi.abi, nft_contract);
    contract.options.gas = 5000000;
    const result = await contract.methods
      .setApprovalForAll(to, status)
      .send({ from: account });
    if (result.status && result.transactionhash != "") {
      return {
        success: true,
        data: {
          owner: result.events.ApprovalForAll.returnValues.owner,
          operator: result.events.ApprovalForAll.returnValues.operator,
          status: result.events.ApprovalForAll.returnValues.approved,
        },
      };
    } else {
      return new Error(result);
    }
  } catch (err: any) {
    return new Error(err);
  }
};

export default KlaytnSetIsApprovalForAll;
