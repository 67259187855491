import { useEffect, useRef, useState } from "react";
import Modal from "../../common/Modal";
import IsMitingModalContent from "../register/IsMitingModalContent";
import SelectDownArrow from "../../../../common/svg/SelectDownArrow";
import UploadSVG from "../../../../common/svg/UploadSVG";
import instance from "../../../Feat/axios/instance";
import MintingEtcNFT from "../../../Feat/nft/MintingEtcNFT";
import KlaytnEtcMinting from "../../../Feat/nft/klaytn/KlaytnEtcMinting";
import WarningToastify from "../../common/WarningToastify";
import NotiToastify from "../../common/NotiToastify";
import CheckCurrentNetwork from "../../../Feat/network/CheckCurrentNetwork";
import CheckValidationServiceChain from "../../../Feat/wallet/CheckValidationServiceChain";
import { useNavigate } from "react-router-dom";

const AdoptRegisterNFT = () => {
  const navigate = useNavigate();
  const [isActiveMinting, setIsActiveModal] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [isAgreed, setIsAgreed] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isActiveAnimalList, setIsActiveAnimalList] = useState(false);
  const [rescuedYear, setRescuedYear] = useState(0);
  const [rescuedMonth, setRescuedMonth] = useState(0);
  const [rescuedDay, setRescuedDay] = useState(0);
  const [noticeNetwork, setNoticeNetwork] = useState("");
  const [registerAnimalList, setRegisterAnimalList] = useState<any[]>([]);
  const [selectedPet, setSelectedPet] = useState("");
  const nftType = "ADOPTION";
  const [petId, setPetId] = useState("");
  const [mintingData, setMintingData] = useState({
    selectedPet: selectedPet,
    name: "",
    description: "",
    rescueDate: "",
    abnormalBehavior: "",
    rescueReason: "",
    rescuePlace: "",
    character: "",
    image: null,
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile: File | null = e.target.files?.[0] || null;
    setFile(selectedFile);
    setMintingData((prev: any) => ({
      ...prev,
      image: selectedFile,
    }));
  };

  const getPetList = () => {
    if (sessionStorage.getItem("login_user")) {
      const userStatusString: any = sessionStorage.getItem("login_user");
      const userStatus = JSON.parse(userStatusString);
      if (userStatus.type == "RIGHTS_GROUP") {
        const url = process.env.REACT_APP_SERVER_API + "/pets/list";
        const res = instance.get(url);
        res.then((item) => {
          setRegisterAnimalList(item.data.data);
        });
      } else {
      }
    } else {
      alert("로그인을 먼저 해주세요!");
      window.location.href = "/";
      return;
    }
  };

  const checkValidateUserStatus = () => {
    if (sessionStorage.getItem("login_user") !== null) {
      const userStatusString: any = sessionStorage.getItem("login_user");
      const userStatus = JSON.parse(userStatusString);
      if (userStatus.type == "RIGHTS_GROUP") {
        if (userStatus.walletType == "METAMASK") {
          const currentNetwork = CheckCurrentNetwork();
          currentNetwork.then((network) => {
            const result = CheckValidationServiceChain(network);
            if (!result) {
              setNoticeNetwork(
                "* 서비스 제공중인 네트워크로 연결해주세요 (서비스 체인 : SEPOLIA,BNB,BAOBAB,CRONOS)"
              );
              return;
            } else {
              setNoticeNetwork(
                "* 현재 네트워크는 이용 가능한 네트워크 체인입니다. "
              );
              return;
            }
          });
        }
        return;
      } else if (
        userStatus.type == "FEED_COMPANY" ||
        userStatus.type == "USER"
      ) {
        WarningToastify("기관 계정으로 로그인해주세요.");
        navigate("/");
      }
    } else {
      NotiToastify("로그인을 먼저 진행해주세요");
      navigate("/");
      return;
    }
  };

  const validateCheck = () => {
    if (mintingData.selectedPet == "" || undefined || null) {
      WarningToastify("동물 NFT를 선택해주세요.");
      return;
    } else if (mintingData.name == "" || undefined || null) {
      WarningToastify("제목을 입력해주세요.");
      return;
    } else if (
      rescuedYear === 0 ||
      null ||
      rescuedMonth === 0 ||
      null ||
      rescuedDay === 0 ||
      null
    ) {
      WarningToastify("구조일자를 올바르게 입력해주세요.");
      return;
    } else if (mintingData.abnormalBehavior == "" || undefined || null) {
      WarningToastify("유기동물 이상행동 여부를 입력해주세요.");
      return;
    } else if (mintingData.rescueReason == "" || undefined || null) {
      WarningToastify("구조 사유를 입력해주세요.");
      return;
    } else if (mintingData.rescuePlace == "" || undefined || null) {
      WarningToastify("구조 장소를 입력해주세요.");
      return;
    } else if (mintingData.character == "" || undefined || null) {
      WarningToastify("성격을 입력해주세요.");
      return;
    } else if (mintingData.image == null || undefined || "") {
      WarningToastify("이미지를 업로드 해주세요.");
      return;
    } else if (!isAgreed) {
      WarningToastify("필수 동의에 체크해주세요.");
      return false;
    }
    return true;
  };

  useEffect(() => {
    checkValidateUserStatus();
    getPetList();
  }, []);

  useEffect(() => {
    const date = rescuedYear + "-" + rescuedMonth + "-" + rescuedDay;
    setMintingData((prev: any) => ({
      ...prev,
      clinicDate: date,
    }));
  }, [rescuedYear, rescuedMonth, rescuedDay]);

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <Modal
        content={<IsMitingModalContent />}
        isActiveModal={isActiveMinting}
        closeModal={setIsActiveModal}
      />
      <div className="flex flex-col items-center justify-center mt-[40px] mb-[120px]">
        <div className="w-full">
          <div className="border-[2px] border-brand100 font-semibold rounded-[30px] w-[90px] text-brand100 flex items-center justify-center h-[36px]">
            입양 NFT
          </div>
          <h3 className="text-[42px] text-brand100 font-semibold w-full mb-[10px] mt-[5px]">
            동물 NFT 생성
          </h3>
          <div className="text-brand100 mb-[20px]"> {noticeNetwork}</div>
        </div>

        <div className="flex mt-[40px] w-full">
          <div>
            <div className="flex mb-[60px]">
              <span className="register-form-name">NFT 선택</span>
              <div>
                <div className="absolute ">
                  <div className="select-animal-list-body">
                    <div
                      className="flex items-center justify-center "
                      onClick={() => {
                        setIsActiveAnimalList(!isActiveAnimalList);
                      }}
                    >
                      <div
                        className={`mr-2  ${isActiveAnimalList ? "mt-1" : ""}`}
                      >
                        {selectedPet || " 등록된 동물 NFT 이름"}
                      </div>

                      <SelectDownArrow
                        width={20}
                        height={20}
                        fillColor="#e06612"
                      />
                    </div>
                    {isActiveAnimalList &&
                      registerAnimalList.map((item) => (
                        <div
                          key={item.petId}
                          className="my-2 hover:text-brand100"
                          onClick={() => {
                            setIsActiveAnimalList(false);
                            setPetId(item.petId);
                            const selectedAnimal =
                              item.petId + "/" + item.breed + "/" + item.name;
                            setSelectedPet(selectedAnimal);
                            setMintingData((prev: any) => ({
                              ...prev,
                              selectedPet: selectedAnimal,
                            }));
                          }}
                        >
                          {item.petId}&#47;
                          {item.breed}&#47;
                          {item.name}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex">
              <span className="register-form-name">제목</span>
              <input
                type="text"
                placeholder="제목입력"
                maxLength={30}
                className=" border-gray40  pl-7 text-gray80  h-[42px] w-[240px]   rounded-[30px] "
                onChange={(e: any) => {
                  setMintingData((prev: any) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="flex mt-[40px]">
              <span className="register-form-name">구조일자</span>
              <div>
                <input
                  onKeyDown={(e) => e.preventDefault()}
                  type="number"
                  min={2010}
                  max={new Date().getFullYear()}
                  className=" border-brand100 text-brand100 h-[36px] w-[100px] text-center  rounded-[30px]"
                  onChange={(e: any) => {
                    setRescuedYear(e.target.value);
                  }}
                />
                <span className="text-brand100 pl-2">년</span>
              </div>
              <div>
                <input
                  onKeyDown={(e) => e.preventDefault()}
                  min={1}
                  max={12}
                  type="number"
                  className="ml-7 border-brand100 text-brand100 h-[36px] w-[76px] text-center  rounded-[30px]"
                  onChange={(e: any) => {
                    setRescuedMonth(e.target.value);
                  }}
                />
                <span className="text-brand100 ml-2">월</span>
              </div>

              <div>
                <input
                  onKeyDown={(e) => e.preventDefault()}
                  type="number"
                  min={1}
                  max={31}
                  className="ml-5 border-brand100  text-brand100 h-[36px] w-[76px] text-center  rounded-[30px]"
                  onChange={(e: any) => {
                    setRescuedDay(e.target.value);
                  }}
                />
                <span className="text-brand100 ml-2">일</span>
              </div>
            </div>

            <div className="flex mt-[40px]">
              <span className="register-form-name">입양 내용</span>
              <div className="flex flex-col space-y-5">
                <input
                  type="text"
                  placeholder="유기동물 이상행동 여부"
                  maxLength={100}
                  className=" border-gray40  pl-7 text-gray80  h-[40px] w-[360px]   rounded-[30px] "
                  onChange={(e: any) => {
                    setMintingData((prev: any) => ({
                      ...prev,
                      abnormalBehavior: e.target.value,
                    }));
                  }}
                />
                <input
                  type="text"
                  placeholder="구조 사유"
                  maxLength={100}
                  className=" border-gray40  pl-7 text-gray80  h-[40px] w-[360px]   rounded-[30px] "
                  onChange={(e: any) => {
                    setMintingData((prev: any) => ({
                      ...prev,
                      rescueReason: e.target.value,
                    }));
                  }}
                />
                <input
                  type="text"
                  placeholder="구조 장소"
                  maxLength={100}
                  className=" border-gray40  pl-7 text-gray80  h-[40px] w-[360px]   rounded-[30px] "
                  onChange={(e: any) => {
                    setMintingData((prev: any) => ({
                      ...prev,
                      rescuePlace: e.target.value,
                    }));
                  }}
                />
                <input
                  type="text"
                  placeholder="성격 (양육일기)"
                  maxLength={300}
                  className=" border-gray40  pl-7 text-gray80  h-[40px] w-[360px]   rounded-[30px] "
                  onChange={(e: any) => {
                    setMintingData((prev: any) => ({
                      ...prev,
                      character: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="sm:ml-[140px] relative  w-[400px]">
              <h3 className="font-bold  text-gray100 mb-[16px]">
                파일 업로드 및 미리보기
              </h3>
              {file ? (
                <img
                  src={URL.createObjectURL(file)}
                  className="sm:w-[420px] sm:h-[420px] relative object-cover "
                  alt="선택한 이미지"
                />
              ) : (
                <div className="sm:w-[420px] sm:h-[420px] bg-[#F7F7F7] flex items-center justify-center">
                  이미지를 선택하세요
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="absolute right-[14px] top-[58px] rounded-[30px] h-[34px] flex items-center justify-center bg-brand100 text-white w-[126px] text-center cursor-pointer"
              >
                <span className="mr-2"> 파일 업로드</span>
                <UploadSVG fillColor="#ffffff" height={16} width={16} />
              </button>

              <div className="flex flex-row items-center w-full mt-[140px]">
                <input
                  type="checkbox"
                  className="rounded-full border border-brand100 checked:bg-brand100"
                  checked={isAgreed}
                  onChange={(e) => {
                    setIsAgreed(e.target.checked);
                  }}
                />
                <h3 className="text-brand100 ml-2 font-semibold">
                  생성된 NFT에 대해 수정이 불가능합니다. 동의하십니까?
                </h3>
              </div>

              <div className="mt-[60px]  w-full flex items-center justify-center">
                <button
                  type="button"
                  className="bg-brand100 text-white w-[140px] h-[46px] rounded-[30px] text-[22px]"
                  onClick={() => {
                    const userStatusString: any =
                      sessionStorage.getItem("login_user");
                    const userStatus = JSON.parse(userStatusString);
                    const validateStatus = validateCheck();
                    if (validateStatus) {
                      if (userStatus.walletType == "METAMASK") {
                        MintingEtcNFT(
                          mintingData,
                          petId,
                          setIsActiveModal,
                          nftType
                        );
                      } else if (userStatus.walletType == "KAIKAS") {
                        KlaytnEtcMinting(
                          mintingData,
                          petId,
                          setIsActiveModal,
                          nftType
                        );
                      }
                    }
                  }}
                >
                  민팅하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdoptRegisterNFT;
