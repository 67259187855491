import instance from "../axios/instance";

const SendWithdrawInfoToServer = async (saleId: number) => {
  try {
    const url = process.env.REACT_APP_SERVER_API + "/nfts/withdraw/" + saleId;
    const response = await instance.patch(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return true;
  } catch (error: any) {
    console.log("서버에러 ", error);
  }
};

export default SendWithdrawInfoToServer;
