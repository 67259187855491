import MainContainer from "../../components/UI/main/MainContainer";
import MainBottomBanner from "../../components/UI/main/bottomBanner/MainBottomBanner";
import EmergencyIntroContainer from "../../components/UI/main/emergency/EmergencyIntroContainer";
import MainIntroduceContainer from "../../components/UI/main/introduce/MainIntroduceContainer";
import SearchMapContainer from "../../components/UI/main/searchMap/SearchMapContainer";

const MainPage = () => {
  return (
    <MainContainer>
      <MainIntroduceContainer />
      <EmergencyIntroContainer />
      <SearchMapContainer />
      {/* <MainBottomBanner /> */}
    </MainContainer>
  );
};

export default MainPage;
