import EyeSVG from "../../../common/svg/EyeSVG";
import HeartSVG from "../../../common/svg/HeartSVG";
import FullHeartSVG from "../../../common/svg/FullHeartSVG";
import { useEffect, useState } from "react";
import WarningToastify from "../common/WarningToastify";
import instance from "../../Feat/axios/instance";
import { useNavigate } from "react-router-dom";
import MappingCurrencyType from "../../Feat/common/MappingCurrencyType";

const NftListCard = (item: any) => {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(`/nftDetail/${item.item.nftId}`);
  };
  const [likeStatus, setLikeStatus] = useState(false);
  const [likeNum, setLikeNum] = useState(0);
  const [currencyType, setCurrencyType] = useState<string | undefined>("");

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };
  const LikeButtonHandler = async () => {
    if (likeStatus == true) {
      setLikeStatus(false);
      try {
        const url =
          process.env.REACT_APP_SERVER_API + "/nfts/dislike/" + item.item.nftId;
        const res = await instance.delete(url);
        if (res.status == 200) {
          setLikeNum(likeNum - 1);
        }
      } catch (error: any) {}
    } else {
      setLikeStatus(true);
      try {
        const url =
          process.env.REACT_APP_SERVER_API + "/nfts/like/" + item.item.nftId;
        const res = await instance.post(url);
        if (res.status == 200) {
          setLikeNum(likeNum + 1);
        }
      } catch (error: any) {
        if (error.response.data.message) {
          WarningToastify(error.response.data.message);
          return;
        } else {
          alert("알 수 없는 에러가 발생했습니다.");
        }
      }
    }
  };

  useEffect(() => {
    setLikeStatus(item.item.userLikesNft);
    setLikeNum(item.item.likeCount);
    const currencyResult: string | undefined = MappingCurrencyType(
      item.item.network
    );
    setCurrencyType(currencyResult);
  }, [item]);

  return (
    <div
      className={`mb-[25px] w-[259px]  h-fit flex flex-col items-center rounded-lg cursor-pointer transform hover:scale-105`}
    >
      <img
        onClick={handleCardClick}
        src={item.item.image}
        className="h-[259px] w-full rounded-[10px] object-cover object-center"
      />
      <div className=" flex flex-col w-full">
        <div className="w-full  flex content-between justify-between items-center mt-[20px] ">
          <div className="text-[20px] font-semibold bg-brand100 rounded-[20px] text-white w-[150px] h-[36px] flex items-center justify-center">
            {truncateText(item.item.name, 6)}
          </div>
          <div className="flex space-x-[12px] items-center justify-center ">
            <div
              className="flex flex-col items-center"
              onClick={() => {
                if (sessionStorage.getItem("login_user")) {
                  LikeButtonHandler();
                } else {
                  WarningToastify("로그인을 먼저 진행해주세요.");
                  return;
                }
              }}
            >
              {likeStatus ? (
                <FullHeartSVG width={22} height={22} fillColor="#e06612" />
              ) : (
                <HeartSVG width={22} height={22} fillColor="#e06612" />
              )}

              <span className="text-[12px] text-brand100">{likeNum}</span>
            </div>
            <div className="flex flex-col items-center">
              <EyeSVG fillColor="#e06612" width={22} height={22} />
              <span className="text-[12px] text-brand100">
                {item.item.viewCount}
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-centers items-center w-full mt-[10px]">
          <span className="text-[#390000] font-semibold">Price</span>
          <div className="ml-3 text-brand100 font-semibold">
            {item.item.price}
            <span className="ml-1">{currencyType}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftListCard;
