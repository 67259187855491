import { Carousel } from "flowbite-react";
import { SampleImageList } from "../../../../common/sampleData/sampleData";

const EmergencyIntroSlide = () => {
  const customTheme = {
    root: {
      leftControl:
        "absolute top-[170px] left-[-60px] flex items-center justify-center px-4 focus:outline-none  main-left-controller_emergency",
      rightControl:
        "absolute top-[170px]  right-[-60px] flex items-center justify-center px-4 focus:outline-none main-right-controller_emergency",
    },
  };

  // const bgColors = ["#FCC22F", "#EB463F", "#FCC22E", "#69BA6E"];

  const imageGroups = Array.from(
    { length: Math.ceil(SampleImageList.length / 4) },
    (_, groupIndex) =>
      SampleImageList.slice(groupIndex * 4, (groupIndex + 1) * 4)
  );

  return (
    <div className="flex items-center h-[380px] justify-center w-[90%] mt-[20px]">
      <Carousel theme={customTheme} className="w-[90%]">
        {imageGroups.map((imageGroup, groupIndex) => (
          <div
            className="flex items-center justify-center space-x-3 h-full"
            key={groupIndex}
          >
            {imageGroup.map((imageInfo, index) => (
              <div className="relative" key={index}>
                <img
                  src={imageInfo.imageUrl}
                  className="object-cover aspect-square max-h-[140px] sm:max-h-[320px] w-full h-full rounded-lg"
                />
                {/* <div className="absolute w-full flex items-center justify-center">
                  <div
                    className="rounded-[20px] text-white absolute bottom-[-13px] flex items-center justify-center sm:w-[200px] sm:h-[32px] font-semibold w-[70px] sm:text-[22px] text-[10px]"
                    style={{ backgroundColor: bgColors[index] }}
                  >
                    {imageInfo.title}
                  </div>
                </div>
                <h3 className="mt-[24px] text-center sm:text-[18px] text-[10px]">
                  진돗개, 1세 추정
                </h3> */}
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default EmergencyIntroSlide;
