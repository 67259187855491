import React, { useEffect, useRef } from "react";
import SelectDownArrow from "../../../common/svg/SelectDownArrow";
import SelctUpArrow from "../../../common/svg/SelctUpArrow";

interface CustomSelectProps {
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  options: string[];
  selectOption: string | null;
}

function CustomSelect({
  setSelectedOption,
  isOpen,
  setIsOpen,
  options,
  selectOption,
}: CustomSelectProps) {
  const selectRef: any = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  // 외부 클릭을 감지하는 이벤트 핸들러
  const handleClickOutside = (event: MouseEvent) => {
    if (
      isOpen &&
      selectRef.current &&
      !selectRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // 외부 클릭 이벤트 핸들러를 document에 추가
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 핸들러 제거
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className="relative flex flex-col w-[160px] text-brand100 cursor-pointer border-b-[#F5D9C6] border-b-[2px]"
      ref={selectRef}
    >
      <div
        className=" w-full h-[40px] flex items-center justify-between"
        onClick={toggleDropdown}
      >
        <h3 className="ml-2">{selectOption}</h3>
        <div className="mr-2">
          {isOpen ? (
            <SelctUpArrow width={20} height={20} fillColor="#E06513" />
          ) : (
            <SelectDownArrow width={20} height={20} fillColor="#E06513" />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="absolute text-brand100 border-brand100 h-full flex flex-col top-[40px] w-full">
          <ul className="rounded-br-[10px] bg-white z-40 rounded-bl-[10px] border border-[#f5d9c6]">
            {options.map((option, index) => (
              <li
                key={index}
                className="h-[40px] items-center flex ml-3 my-2 hover:text-[#dec4b2]"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CustomSelect;
