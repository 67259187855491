import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import NftListCard from "./NftListCard";
import GetBuyingNFTList from "../../Feat/nft/GetBuyingNFTList";
import { searchKeywordState } from "../../../utils/recoil/searchKeyword/atom";
import { useRecoilState } from "recoil";
import CustomSelect from "../common/CustomSelect";
import GetBuyingNFTListAsLogin from "../../Feat/nft/GetBuyingNFTListAsLogin";

const NftContainer = () => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 12;
  const [nftList, setNftList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchKeyword, setSearchKeyword] = useRecoilState(searchKeywordState);

  const [nftTypeIsOpen, setNftTypeIsOpen] = useState(false);
  const [nftType, setNftType] = useState<string>("NFT 종류");
  const nftTypeOption = [
    "전체",
    "등록 NFT",
    "생활 NFT",
    "진료 NFT",
    "입양 NFT",
    "기부 NFT",
  ];
  const nftTypeMapping: any = {
    전체: "ALL",
    "NFT 종류": "ALL",
    "등록 NFT": "REGISTRATION",
    "생활 NFT": "LIFESTYLE",
    "진료 NFT": "MEDICAL",
    "입양 NFT": "ADOPTION",
    "기부 NFT": "DONATION",
  };

  // network type select
  const [networkTypeIsOpen, setNetworkTypeIsOpen] = useState(false);
  const [network, setNetwork] = useState<string>("체인별 필터");
  const networkTypeOption = [
    "전체",
    "바오밥",
    "크로노스",
    "비앤비체인",
    "세폴리아",
  ];
  const networkTypeMapping: any = {
    전체: "ALL",
    세폴리아: "SEPOLIA",
    비앤비체인: "BNB",
    크로노스: "CRONOS",
    바오밥: "BAOBAB",
  };

  // sortBy type select
  const [sortByTypeIsOpen, setSortByTypeIsOpen] = useState(false);
  const [sortBy, setSortBy] = useState("최신순");
  const sortByTypeOption = [
    "최신순",
    "종료 임박 순",
    "가격 높은 순",
    "가격 낮은 순",
  ];
  const sortByTypeMapping: any = {
    최신순: "RECENT",
    "가격 높은 순": "PRICE_DESC",
    "가격 낮은 순": "PRICE_ASC",
    "종료 임박 순": "ENDING_SOON",
  };

  const fetchNFTListOnPageChange = async (selectedPage: any) => {
    try {
      const response: any = await GetBuyingNFTList({
        keyword: searchKeyword,
        network: networkTypeMapping[network],
        nftType: nftTypeMapping[nftType],
        sortBy: sortByTypeMapping[sortBy],
        page: selectedPage - 1,
        size: itemsPerPage,
      });

      if (response.data && response.data.status === 200) {
        setNftList(response.data.data.content);
        setTotalPages(response.data.data.totalPages);
      } else {
        console.error("NFT 목록을 불러오는데 실패했습니다.");
      }
    } catch (error) {
      console.error("에러:", error);
    }
  };

  const handlePageChange = (selectedPage: any) => {
    setPage(selectedPage);
    fetchNFTListOnPageChange(selectedPage);
  };
  const fetchNFTList = async () => {
    try {
      const response: any = await GetBuyingNFTList({
        keyword: searchKeyword,
        network: networkTypeMapping[network],
        nftType: nftTypeMapping[nftType],
        sortBy: sortByTypeMapping[sortBy],
        page: page - 1,
        size: itemsPerPage,
      });
      if (response.data && response.data.status === 200) {
        setNftList(response.data.data.content);
        setTotalPages(response.data.data.totalPages);
      } else {
        console.error("NFT 목록을 불러오는데 실패했습니다.");
      }
    } catch (error) {
      console.error("에러:", error);
    }
  };

  const fetchNFTListAsUser = async () => {
    const response: any = await GetBuyingNFTListAsLogin({
      keyword: searchKeyword,
      network: networkTypeMapping[network],
      nftType: nftTypeMapping[nftType],
      sortBy: sortByTypeMapping[sortBy],
      page: page - 1,
      size: itemsPerPage,
    });
    if (response.data && response.data.status === 200) {
      setNftList(response.data.data.content);
      setTotalPages(response.data.data.totalPages);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("login_user") !== null) {
      fetchNFTListAsUser();
    } else {
      fetchNFTList();
    }
  }, [page, network, nftType, sortBy]);

  return (
    <div className="flex flex-col justify-center items-center ">
      <div className=" mt-[80px] flex flex-col items-center max-w-[calc(1280px+60px)] ">
        <div className="flex flex-col justify-center w-full mb-[40px]">
          <h3 className="text-[40px] font-bold text-[#e06612]">NFT LIST</h3>
          <h4 className="text-[26px] text-[#707070] mt-3">현재 판매중인 NFT</h4>
        </div>
        <div className="w-full  md:min-w-[1200px] flex sm:flex-row flex-col justify-between items-center mt-[50px]">
          {/* 검색창 */}
          <nav className="sm:max-w-[460px]  max-w-[360px] w-full md:mr-auto flex flex-wrap items-center text-base justify-center">
            <div className="relative w-full">
              <div className="absolute inset-y-0 right-0 mr-5 flex items-center ">
                <div className="h-[25px] border border-[#ECA26F] w-[1px]"></div>
                <svg
                  className="w-[15.9px] h-[15.9px] text-[#E06514] font-semibold ml-[17px] cursor-pointer hover:text-orange-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                className="w-full placeholder:text-[#E06713] h-[42px] p-4 pl-6 text-[18px] text-[#9F9F9F] border border-[#E06514] rounded-[30px]"
                placeholder="검색어 입력"
                defaultValue={searchKeyword}
                onChange={(e: any) => {
                  setSearchKeyword(e.target.value);
                }}
                onKeyDown={(e: any) => {
                  if (e.code === "Enter") {
                    fetchNFTList();
                  }
                }}
              />
            </div>
          </nav>
          <div className="flex space-x-4 sm:flex-row flex-col ">
            <CustomSelect
              selectOption={nftType}
              setSelectedOption={setNftType}
              isOpen={nftTypeIsOpen}
              setIsOpen={setNftTypeIsOpen}
              options={nftTypeOption}
            />
            <CustomSelect
              selectOption={network}
              setSelectedOption={setNetwork}
              isOpen={networkTypeIsOpen}
              setIsOpen={setNetworkTypeIsOpen}
              options={networkTypeOption}
            />
            <CustomSelect
              selectOption={sortBy}
              setSelectedOption={setSortBy}
              isOpen={sortByTypeIsOpen}
              setIsOpen={setSortByTypeIsOpen}
              options={sortByTypeOption}
            />
          </div>
        </div>
        {nftList.length == 0 && (
          <div className="min-h-screen flex items-center justify-center w-full  text-brand100  text-[20px]">
            * 해당하는 데이터가 없습니다.
          </div>
        )}
        {nftList.length !== 0 && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-7 mt-[80px] w-full min-h-screen mb-[80px]">
            {nftList.map((item: any, index) => (
              <div
                className="mb-4 h-fit transform transition-transform hover:scale-105 "
                key={index}
              >
                <NftListCard item={item} key={index} />
              </div>
            ))}
          </div>
        )}

        <Pagination
          activePage={page}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalPages * itemsPerPage}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="inline-block mx-1 text-[22px] font-semibold   px-2 py-1 rounded-lg cursor-pointer mb-[80px] "
          activeClass=" text-brand100 text-[22px] font-semibold "
          disabledClass="text-[#9f9f9f]  "
          hideFirstLastPages={true}
          innerClass="text-[#9f9f9f]"
          prevPageText={<div className="text-brand100">&#60;</div>}
          nextPageText={<div className="text-brand100">&#62;</div>}
        />
      </div>
    </div>
  );
};

export default NftContainer;
