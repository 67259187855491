import { atom } from "recoil";

export const signUpUserState = atom({
  key: "signUpUser",
  default: {
    name: "",
    email: "",
    image:
      "https://sixsteps.s3.ap-northeast-2.amazonaws.com/whatcon/whatcon_logo.png",
    role: "",
    adAgreed: false,
    walletList: [{}],
  },
});

// export const signUpSocialUserState = atom({
//   key: "signUpUser",
//   default: {
//     name: "",
//     email: "",
//     image:
//       "https://sixsteps.s3.ap-northeast-2.amazonaws.com/whatcon/whatcon_logo.png",
//     role: "",
//     adAgreed: false,
//     walletList: [{}],
//   },
// });
