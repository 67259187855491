import { warn } from "console";
import NotiToastify from "../../../UI/common/NotiToastify";
import WarningToastify from "../../../UI/common/WarningToastify";
import instance from "../../axios/instance";
import ValidateLoginWalletList from "../../wallet/ValidateLoginWalletList";
import ConnectKaikas from "../../wallet/klaytn/ConnectKaikas";
import UploadDataToNftStorage from "../UploadDataToNftStorage";
import KlaytnMinting from "./KlaytnMinting";
import CheckCurrentKlaytnNetwork from "../../wallet/klaytn/CheckCurrentKlaytnNetwork";
import MatchChainIdAndNetworkName from "../../network/MatchChainIdAndNetworkName";

const KlaytnEtcMinting = async (
  mintingData: any,
  petId: any,
  setIsActiveModal: any,
  nftType: any
) => {
  const currentUserWallet = await ConnectKaikas();
  const validateWalletStatus = await ValidateLoginWalletList(currentUserWallet);

  if (!validateWalletStatus) {
    WarningToastify("등록된 지갑주소로 연결해주세요.");
    return;
  }

  setIsActiveModal(true);
  const responseNft: any = await UploadDataToNftStorage(mintingData);
  if (responseNft.status) {
    const metaData = responseNft.data;
    const ipfsURI: any = responseNft.ifpsURL;

    if (
      metaData !== null &&
      metaData !== undefined &&
      ipfsURI !== null &&
      ipfsURI !== undefined
    ) {
      const { chainId }: any = await CheckCurrentKlaytnNetwork();
      const networkName: any = MatchChainIdAndNetworkName(chainId);

      const result: any = await KlaytnMinting(ipfsURI, networkName);
      if (result.success == true && result.data.NFTId) {
        // data.tokenId = result?.data.NFTId;
        // data.mintingTransactionHash = result?.transactionHash;
        const sendToServerData = {
          walletAddress: currentUserWallet,
          petId: petId,
          name: metaData.name,
          image: metaData.image,
          nftType: nftType,
          metaData: metaData,
          tokenId: result?.data.NFTId,
          network: networkName,
          mintingTransactionHash: result?.transactionHash,
        };
        try {
          const response = await instance.post(
            process.env.REACT_APP_SERVER_API + "/nfts/minting",
            sendToServerData,
            {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            }
          );

          if (response.status == 200) {
            setIsActiveModal(false);
            NotiToastify("민팅에 성공했습니다.");
            window.location.href = "/agency/mypage";
          } else {
            setIsActiveModal(false);
          }
        } catch (error: any) {
          console.log("서버에 민팅 데이터 업로드 실패", error);
        }
      } else {
        setIsActiveModal(false);
        NotiToastify("블록체인 민팅에 실패했습니다. 다시 시도해주세요.");
        return;
      }
    }
  } else if (!responseNft.status) {
    setIsActiveModal(false);
    WarningToastify("ipfs 업로드에 실패했습니다.");
    return;
  }
};

export default KlaytnEtcMinting;
