import { ethers } from "ethers";
import MatchChainIdAndContractAdd from "../network/MatchChainIdAndContractAdd";
import CheckCurrentNetwork from "../network/CheckCurrentNetwork";
import MatchChainIdAndRouterAdd from "../network/MatchChainIdAndRouterAdd";
import MatchChainIdAndAuction from "../network/MatchChainIdAndAuction";
import SendSellNFTInfoToServer from "./SendSellNFTInfoToServer";
import WarningToastify from "../../UI/common/WarningToastify";

const SellNFT = async (
  tokenId: number,
  price: number,
  date: number,
  nftId: number
) => {
  let provider = new ethers.BrowserProvider(window.ethereum);
  let signer = await provider.getSigner();

  const nftcontractabi = require("../../../utils/contract/StandardNFT.json");
  const currentNetwork = await CheckCurrentNetwork();
  const nftcontractAddress: any = MatchChainIdAndContractAdd(currentNetwork);
  const abi = nftcontractabi.abi;
  const nftContract = new ethers.Contract(nftcontractAddress, abi, signer);

  const router: any = MatchChainIdAndRouterAdd(currentNetwork);

  const routerContractAbi = require("../../../utils/contract/MarketplaceRouter.json");
  const routerContract = new ethers.Contract(
    router,
    routerContractAbi.abi,
    signer
  );

  const isApproved = await nftContract.isApprovedForAll(signer.address, router);

  if (!isApproved) {
    const approveTx = await nftContract.setApprovalForAll(router, true);
    await approveTx.wait();
  }

  const platform: any = MatchChainIdAndAuction(currentNetwork);

  if (platform) {
    const saleRegisterParam = {
      Platform: platform,
      NftId: tokenId,
      Price: String(price * 10 ** 18),
      Time: date * 60 * 60 * 24,
    };

    try {
      const sales = await routerContract.creationAuction(
        saleRegisterParam.Platform,
        saleRegisterParam.NftId,
        nftcontractAddress,
        saleRegisterParam.Price,
        saleRegisterParam.Time,
        "0x0000000000000000000000000000000000000000"
      );
      const sale = await sales.wait();
      if (sale) {
        const transactionHash = sale.logs[1]["transactionHash"];
        const auctionNumber = Number(sale.logs[1].args[1]);
        const sellerWalletAddress = sale.logs[1].args[0];

        const data = {
          nftId,
          sellerWalletAddress: sellerWalletAddress,
          dueDate: date,
          price,
          auctionNumber,
          saleTransactionHash: transactionHash,
        };

        const result: any = await SendSellNFTInfoToServer(data);
        return { status: true, message: "판매 성공" };
      }
    } catch (error: any) {
      if (error.code !== "ACTION_REJECTED") {
        console.error("An error occurred during the minting process:", error);
      } else {
        alert("거부되었습니다");
      }
      return { status: false };
    }
  } else {
    return { status: false };
  }
};

export default SellNFT;
